import PropTypes from 'prop-types';
// @mui
import { Grid } from '@mui/material';
import CircuitCard from './CircuitCard';

// ----------------------------------------------------------------------
CircuitList.propTypes = {
  circuits: PropTypes.array.isRequired,
};

export default function CircuitList({ circuits, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {circuits.map((circuit) => (
        <Grid key={circuit.id} item xs={12} sm={6} md={3}>
          <CircuitCard circuit={circuit} />
        </Grid>
      ))}
    </Grid>
  );
}
