import axios from 'axios';
import CookieService from '../cookie-service';
import KEYS from '../helpers/keys';

const authHeader = () => {
  const token = CookieService.getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  return {};
};

const allUsers = async (newParams) => {
  const url = `${KEYS.BACKEND_HOST_USER}/all-users`;

  const headers = authHeader();

  const response = await axios.get(url, { params: { ...newParams }, headers });
  return response.data;
};

const createAccount = async (userEntry) => {
  const url = `${KEYS.BACKEND_HOST_AUTH}/signup`;

  const headers = authHeader();

  const response = await axios.post(url, { ...userEntry }, { headers });
  return response.data;
};
const changeRoleUser = async (params) => {
  const url = `${KEYS.BACKEND_HOST_USER}/change-role/${params.userId}`;

  const headers = authHeader();

  const response = await axios.put(url, { role: params.role }, { headers });
  return response.data;
};
const changeStatusUser = async (params) => {
  const url = `${KEYS.BACKEND_HOST_USER}/change-status/${params.userId}`;

  const headers = authHeader();

  const response = await axios.put(url, { status: params.status }, { headers });
  return response.data;
};
const UserService = {
  allUsers,
  createAccount,
  changeRoleUser,
  changeStatusUser,
};
export default UserService;
