import axios from "axios";
import CookieService from '../cookie-service'
import KEYS from '../helpers/keys'

const authHeader = () => {
    const token = CookieService.getToken();
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
    }
    return {};
  };

const login = async (body)=>{
    const url = `${KEYS.BACKEND_HOST_AUTH}/login`;
   
    const headers = authHeader();
    
    const response = await axios.post(url, body, { headers });
    return response.data;
}

const createAccount = ()=>{}


const AuthService = {
    login,
    createAccount
  };
  export default AuthService;