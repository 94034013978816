import axios from 'axios';
import CookieService from '../cookie-service';
import KEYS from '../helpers/keys';

const authHeader = () => {
  const token = CookieService.getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  return {};
};

const allCircuits = async (param) => {
  const url = `${KEYS.BACKEND_HOST_BANTOUR}/all-circuits-admin?page=${param?.page}&limit=${KEYS.LOCAL_LIMIT}`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};
const addCircuit = async (body) => {
  const url = `${KEYS.BACKEND_HOST_BANTOUR}/create-circuit`;
  console.log(url);

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const updateCircuit = async (body) => {
  const url = `${KEYS.BACKEND_HOST_BANTOUR}/update-circuit`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const deleteCircuit = async (circuitId) => {
  const url = `${KEYS.BACKEND_HOST_BANTOUR}/delete-circuit/${circuitId}`;

  const headers = authHeader();

  const response = await axios.delete(url, { headers });
  return response.data;
};

const BantourService = {
  allCircuits,
  addCircuit,
  updateCircuit,
  deleteCircuit,
};
export default BantourService;
