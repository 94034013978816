import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  LinearProgress,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';

import { AddCityForm, CityListHead, CityListToolbar, EditCityForm } from '../sections/@dashboard/city';

import CityService from '../services/city-service';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'nbFocalPoints', label: 'NB. Points Focaux', alignRight: false },
  { id: 'nbDistricts', label: 'NB. Quartier', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CityPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [cityList, setCityList] = useState([]);

  const [totalSize, setTotalSize] = useState(0);

  const [loading, setLoading] = useState(false);

  const [circleProgress, setCircleProgress] = useState(true);

  const [filterList, setFilterList] = useState({ createdAt: -1 });

  const [showForm, setShowForm] = useState(false);

  const [showEditForm, setShowEditForm] = useState(false);

  const [selectedCity, setSelectedCity] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const getAllCities = async () => {
    setLoading(true);
    await CityService.allCities()
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const cities = result.data;
            setCityList(cities);
          }
          setCircleProgress(false);
        },
        (error) => {
          console.log(error);
          setCircleProgress(false);
        }
      )
      .finally(() => {
        setLoading(false);
        setCircleProgress(false);
      });
  };

  const addCity = async (cityParams) => {
    setLoading(true);
    await CityService.addCity(cityParams)
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const city = result.data;
            console.log(city);
            setCityList([city, ...cityList]);
            // setCityList(cities);
          }
          setCircleProgress(false);
        },
        (error) => {
          console.log(error);
          setCircleProgress(false);
        }
      )
      .finally(() => {
        setLoading(false);
        setCircleProgress(false);
        setShowForm(false);
      });
  };

  const updateCity = async (cityParams) => {
    setLoading(true);
    await CityService.updateCity(cityParams)
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const city = result.data;
            console.log(city);
            const newCityList = cityList.map((e) => {
              if (e.id === city.id) return city;
              return e;
            });
            setCityList(newCityList);
            // setCityList(cities);
          }
          setCircleProgress(false);
        },
        (error) => {
          console.log(error);
          setCircleProgress(false);
        }
      )
      .finally(() => {
        setLoading(false);
        setCircleProgress(false);
        setShowEditForm(false);
      });
  };

  const deleteCity = async () => {
    if (selectedCity) {
      setLoading(true);
      await CityService.deleteCity(selectedCity.id)
        .then(
          (result) => {
            if (result.code === 'SUCCESS') {
              const newCityList = cityList.map((e) => {
                if (e.id === selectedCity.id)
                  return {
                    ...selectedCity,
                    isDeleted: !selectedCity.isDeleted,
                  };
                return e;
              });
              setCityList(newCityList);
            }
            setCircleProgress(false);
          },
          (error) => {
            console.log(error);
            setCircleProgress(false);
          }
        )
        .finally(() => {
          setLoading(false);
          setCircleProgress(false);
          setOpen(false);
          setShowEditForm(false);
          setSelectedCity(null);
        });
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const setDataCity = (data) => {
    // console.log(data)
    addCity(data);
  };

  const setUpdateCity = (data) => {
    updateCity(data);
  };

  useEffect(() => {
    getAllCities();
  }, []);

  const handleClose = () => {
    setShowForm(false);
    setShowEditForm(false);
    setSelectedCity(null);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - cityList.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  // const filteredUsers = userList;

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> City | List </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Villes
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            style={{ textTransform: 'none' }}
            onClick={() => setShowForm(true)}
          >
            Ajouter une ville
          </Button>
        </Stack>
        {loading && circleProgress === false && <LinearProgress />}

        {circleProgress ? (
          <CircularProgress />
        ) : (
          <Card>
            <CityListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <CityListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={totalSize}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {cityList.map((row) => {
                      const { cityId, name, districts, isEnabled, focalPoints, createdAt, isDeleted } = row;

                      const selectedCity = selected.indexOf(name) !== -1;

                      return (
                        <TableRow hover key={cityId} tabIndex={-1} role="checkbox" selected={selectedCity}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedCity} onChange={(event) => handleClick(event, name)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="center">{districts.length}</TableCell>

                          <TableCell align="center">{focalPoints.length}</TableCell>

                          <TableCell align="left">
                            <Label color={(isDeleted && 'error') || 'success'}>
                              {!isDeleted ? 'Activée' : 'Désactivée'}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => {
                                handleOpenMenu(event);
                                setSelectedCity(row);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalSize}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </Card>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpen(false);
            setShowForm(false);
            setShowEditForm(true);
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {selectedCity && selectedCity.isDeleted ? (
          <MenuItem
            sx={{ color: 'success.main' }}
            onClick={() => {
              deleteCity();
            }}
          >
            <Iconify icon={'eva:refresh-fill'} sx={{ mr: 2 }} />
            Restore
          </MenuItem>
        ) : (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={() => {
              deleteCity();
            }}
          >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        )}
      </Popover>

      <Dialog
        open={showForm}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Ajouter une nouvelle ville'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <AddCityForm onChangeData={setDataCity} onCloseForm={handleClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showEditForm}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Editer la ville'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            {selectedCity && (
              <EditCityForm onChangeData={setUpdateCity} onCloseForm={handleClose} data={selectedCity} />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
