import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import KEYS from '../../../helpers/keys';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import ProductService from '../../../services/product-service';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const [products, setProducts] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const getAllProducts = async () => {
    console.log('je suis ici');
  };
  const doChange = async (value) => {
    console.log(value);
    try {
      const params = {
        search: value,
        page: 1,
      };
      const result = await ProductService.searchProduct(params);
      if (result.code === 'SUCCESS') {
        setProducts(result.data.data);
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Searchbar onChange={(value) => doChange(value)} hasData={(value) => setIsSearchOpen(value)} />
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <LanguagePopover />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </StyledToolbar>
      {isSearchOpen && (
        <div style={{ color: 'black', backgroundColor: 'white' }}>
          {products && products.length > 0 && (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {products.map((product) => (
                <ListItemButton
                  component="a"
                  key={product.id}
                  style={{ cursor: 'pointer' }}
                  // onClick={() => console.log(product)}
                  href={`/dashboard/products/${product.id}`}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={product.name}
                      src={
                        product.images.length > 0
                          ? `${KEYS.BACKEND_HOST_RESSOURCES}/products/${product.images[0]}`
                          : `${KEYS.BACKEND_HOST_RESSOURCES}/products/masque.png`
                      }
                    >
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={product.name} secondary={product.gramming} />
                </ListItemButton>
              ))}
            </List>
          )}
        </div>
      )}
    </StyledRoot>
  );
}
