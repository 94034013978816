import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Checkbox, Chip, Collapse, Link, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileUploaderMultiple from '../../../components/upload/fileUploaderMultiple';

function AddProductForm(props) {
  const [name, setName] = useState('');
  const [focalPoints, setFocalPoints] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataCity, setDataCity] = useState({});
  const [showImageZone, setShowImageZone] = useState(false);
  const [images, setImages] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState();

  const [allCities, setAllCities] = useState(props.cities);
  const [cities, setCities] = useState();

  const [values, setValues] = useState({});
  const [value, setValue] = useState('');

  const handleChangeCategories = (value) => {
    console.log(values);
    setCategories(value);
    setValues({ ...values, category: value[0] });
  };

  const handleChangeCities = (value) => {
    setCities(value);
    setValues({ ...values, cities: value });
  };

  const handleChangeParameters = (value) => {
    console.log(`handleChangeParameters = ${value}`);
    setParameters(value);
    setValues({ ...values, parameters: value });
  };

  const onFireImages = (imgs) => {
    console.log('Onfire image', imgs);
    setImages((images) => [...imgs]);
    setValues({ ...values, images: imgs });
  };

  const saveData = () => {
    const oldCategory = values.category;
    if (oldCategory && oldCategory.categoryId) {
      const newCategory = oldCategory.categoryId;
      const updated = { ...values, category: newCategory, name };
      console.log(updated);
      props.onSaveData(updated);
    }
  };
  const handleClose = () => {
    props.onCloseForm();
  };
  const buildProductName = () => {
    if (values.name && values.name !== '') {
      const valuesOfParameters = getValuesOfParameter();
      console.log('parameter = ', valuesOfParameters);
      const name = `${values.name}${
        values.gramming !== undefined && values.gramming !== '' ? ` - ${values.gramming}` : ''
      }${valuesOfParameters && valuesOfParameters !== '' && ' - '}${valuesOfParameters}`;
      setName(name);
    } else {
      setName('');
    }
  };
  const getValuesOfParameter = () => {
    if (values.parameters && values.parameters.length > 0) {
      const valuesOfParametres = values.parameters.map((el) => {
        const data = el.split('#');
        return data.length >= 1 && data[1];
      });
      return valuesOfParametres.join(' - ');
    }
    return '';
  };

  const handleAddParameter = (e) => {
    console.log(e);
    if (e.target.value.length > 0 && (e.code === 'Tab' || e.code === 'Enter' || e.key === '+')) {
      e.preventDefault();
      const foundParams = parameters.find((el) => el === e.target.value);
      if (foundParams) return;
      // const newParameters = [...parameters, e.target.value];
      setValues({ ...values, parameters: [...parameters, e.target.value] });
      setParameters([...parameters, e.target.value]);

      setValue('');
    }
  };
  const handleRemove = (index) => {
    const newParameters = parameters.filter((e, i) => i !== index);
    setParameters(newParameters);
    setValues({ ...values, parameters: newParameters });
  };
  useEffect(() => {
    buildProductName();
  }, [values]);
  useEffect(() => {
    setAllCities(props.cities);
    setAllCategories(props.categories);
  }, [props]);
  return (
    <>
      <Stack spacing={3}>
        {name !== '' && <TextField name="text" label="Nom réel" disabled value={name} />}

        <TextField
          name="text"
          label="Nom du produit"
          onChange={(event) => setValues({ ...values, name: event.target.value })}
          placeholder="Entrez le nom du produit"
        />
        {/* <TextField
          name="text"
          label="Nom du produit"
          onChange={(event) => setValues({ ...values, name: event.target.value })}
          placeholder="Entrez le nom du produit"
        /> */}
        <TextField
          name="text"
          label="Grammage"
          onChange={(event) => setValues({ ...values, gramming: event.target.value })}
          placeholder="Entrez le grammage du produit"
        />
        <TextField
          name="text"
          label="Entrez les paramètres valider sur entrer"
          onChange={(e) => setValue(e.target.value)}
          value={value}
          onKeyPress={(event) => handleAddParameter(event)}
          placeholder="Nom_paramètre#valeur_paramètre"
        />
        {parameters && parameters.length > 0 && (
          <div style={{ display: 'flex', gap: '3px' }}>
            {parameters.map((val, index) => (
              <Chip label={val} variant="outlined" onDelete={() => handleRemove(index)} key={index} />
            ))}
          </div>
        )}

        {/* <Autocomplete
          multiple
          id="tags-filled-2"
          defaultValue={[]}
          options={[]}
          freeSolo
          onChange={(event, newValue, reason) => {
            console.log(event);
            if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
              console.log('hereeeee');
              return;
            }
            handleChangeParameters(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Entrez les paramètres valider sur entrer"
                placeholder="Nom_paramètre#valeur_paramètre"
              />
            );
          }}
        /> */}

        <TextField
          id="outlined-textarea"
          label="Description du produit"
          onChange={(event) => setValues({ ...values, description: event.target.value })}
          placeholder="Entrez une description du produit"
          multiline
        />
        <TextField
          id="outlined-textarea-2"
          label="Description du produit en anglais"
          onChange={(event) => setValues({ ...values, descriptionEng: event.target.value })}
          placeholder="English description"
          multiline
        />
        <div className="layout vertical">
          <Link
            underline="none"
            component="button"
            variant="body2"
            onClick={() => {
              if (showImageZone) setShowImageZone(false);
              else setShowImageZone(true);
            }}
          >
            {showImageZone && (
              <Stack className="layout horizontal center" sx={{ mb: 1 }}>
                <div className="layout horizontal center" style={{ display: 'flex', justifyItems: 'center' }}>
                  <KeyboardArrowUp />
                  <span>Cacher la zone d'image ({images.length})</span>
                </div>
              </Stack>
            )}
            {!showImageZone && (
              <Stack className="layout horizontal center">
                <div className="layout horizontal center" style={{ display: 'flex', justifyItems: 'center' }}>
                  <KeyboardArrowDown />
                  <span>Ajouter des images du produit ({images.length})</span>
                </div>
              </Stack>
            )}
          </Link>
          <Collapse orientation="vertical" in={showImageZone}>
            <FileUploaderMultiple disabled={props.submitting} onFire={onFireImages} path="products" />
          </Collapse>
        </div>
        <Autocomplete
          multiple
          id="tags-categories"
          options={allCategories}
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          filterSelectedOptions
          onChange={(event, newValue, reason) => {
            if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
              return;
            }
            handleChangeCategories(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField {...params} label="Sélectionnez les catégories" placeholder="Catégories du produit" />
          )}
        />
        {name !== '' && <TextField name="text" label="Nom réel" disabled value={name} />}

        {/* <Autocomplete
          multiple
          id="tags-cities"
          options={allCities}
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          
          filterSelectedOptions
          
          onChange={(event, newValue, reason) => {
            if (
              event.type === 'keydown' &&
              event.key === 'Backspace' &&
              reason === 'removeOption'
            ) {
              return;
            }
            handleChangeCities(newValue);
          }}
          renderTags={(value, getTagProps) =>
          value.map((option, index) => (
              <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
          ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sélectionnez les villes"
              placeholder="Villes où l'on trouve le produit"
            />
          )}
        /> */}
      </Stack>

      <Stack direction="row" spacing={1} sx={{ mt: 1.5 }} style={{ justifyContent: 'right' }}>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={saveData}>Enregistrer</Button>
      </Stack>
    </>
  );
}

export default AddProductForm;
