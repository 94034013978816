import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import UploadService from './uploadService';
import KEYS from '../../helpers/keys';
import { cancelEventPropagation } from '../../helpers/helpers';

export default function FileUploaderMultiple(props) {
  const MAX_FILE_SIZE_BYTE = 5242880;
  // const MAX_FILE_SIZE_BYTE = 1  //pour les tests
  const MAX_FILE_SIZE_MEGA = '5MO';
  const TYPE_FILE_LIST = 'jpeg,png';

  console.log(props.images);
  const [imageIds, setImageIds] = useState(props.images && props.images.length > 0 ? props.images : []);
  const { id } = useParams();

  const [inProgress, setInProgress] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [type, setType] = useState('EDIT');

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length === 0) {
      setInProgress(true);
      const latestImage = acceptedFiles[0];
      UploadService.upload(latestImage, props.path, id)
        .then((response) => {
          const imageSrc = response.data.imageId;
          doAddImage(imageSrc);
          setInProgress(false);
        })
        .catch((e) => {
          console.log(e.message);
          setInProgress(false);
        });
    } else {
      console.log(fileRejections[0].errors[0].code);
      if (fileRejections[0].errors[0].code === 'file-too-large') {
        enqueueSnackbar(`Fichier très large: limit: ${MAX_FILE_SIZE_MEGA}`, {
          variant: 'warning',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        });
      } else if (fileRejections[0].errors[0].code === 'file-invalid-type') {
        enqueueSnackbar /*
          formatMessage({ id: 'file_invalid_type' }, { listFileType: TYPE_FILE_LIST }),
          {
            variant: 'warning',
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
          } */();
      }
    }
  }, []);

  const deleteImage = (event, index) => {
    cancelEventPropagation(event);
    console.log('Deleting image');
    const src = imageIds.at(index);
    UploadService.deleteImage(props.path, src, id)
      .then((response) => {
        console.log(response);
        removeAtIndex(index);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const removeAtIndex = (index) => {
    const data = [...imageIds];
    data.splice(index, 1);
    setImageIds(data);
  };
  const doAddImage = (id) => {
    setType('ADD');
    setImageIds((imageIds) => [...imageIds, id]);
  };
  useEffect(() => {
    if (props.onFire && type === 'ADD') {
      props.onFire(imageIds);
      setType('EDIT');
    }
  }, [imageIds]);

  useEffect(() => {
    if (props.images && props.images.length > 0) {
      setImageIds([...props?.images]);
    }
  }, [props.images]);

  const { isDragActive, acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/jpeg': [], 'image/png': [] },
    maxFiles: 1,
    minSize: 0,
    maxSize: MAX_FILE_SIZE_BYTE,
  });

  return (
    <div className="layout horizontal center wrap" style={{ display: 'flex' }}>
      <div className="layout horizontal center wrap" style={{ display: 'flex' }}>
        {imageIds.map((src, index) => (
          <div key={index} style={{ margin: '0 5px 5px' }}>
            {' '}
            <ImageItem imageSrc={src} deleteImage={(event) => deleteImage(event, index)} path={props.path} />
          </div>
        ))}
      </div>
      <div
        {...getRootProps()}
        style={{
          width: '150px',
          height: '75px',
          backgroundColor: '#87879231',
          border: '1px dashed #aaa ',
          color: '#414141',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          margin: '0 5px 5px',
        }}
      >
        <input {...getInputProps()} />

        <div
          style={{
            color: '#414141',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <CloudUploadIcon style={{ fontSize: '20px', color: '#777' }} />
          <Typography style={{ textAlign: 'center', fontSize: '10px' }} gutterBottom component="div">
            Déposez ou cliquez pour charger un fichier ici. Uniquement {TYPE_FILE_LIST} et pas plus de{' '}
            {MAX_FILE_SIZE_MEGA}
          </Typography>
        </div>

        {inProgress && (
          <CircularProgress thickness={1.5} color="primary" size="64px" sx={{ position: 'absolute', zIndex: '5px' }} />
        )}
      </div>
    </div>
  );
}

function ImageItem(props) {
  return (
    <div
      style={{
        position: 'relative',
        width: '75px',
        height: '75px',
        backgroundColor: '#87879231',
        border: '1px dashed #aaa ',
      }}
    >
      {props.imageSrc != null && (
        <div style={{ position: 'relative' }}>
          <CardMedia
            component="img"
            height="75px"
            image={`${KEYS.BACKEND_HOST_RESSOURCES}/${props.path}/${props.imageSrc}`}
            alt="Paella dish"
            style={{ objectFit: 'contain' }}
          />
        </div>
      )}
      {/* {inProgress && <CircularProgress thickness={1} color="primary" size='20vh' sx={{ position: 'absolute', zIndex: '5px' }} />} */}

      {props.imageSrc != null && (
        <IconButton
          aria-label="delete"
          sx={{
            color: 'red',
            position: 'absolute',
            top: '0px',
            left: '0px',
            zIndex: '3',
            width: '20px',
            height: '20px',
          }}
          onClick={props.deleteImage}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
}
