import axios from 'axios';
import CookieService from '../cookie-service';
import KEYS from '../helpers/keys';

const authHeader = () => {
  const token = CookieService.getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  return {};
};

const allOrders = async (page) => {
  const limit = 20;
  const url = `${KEYS.BACKEND_HOST_ORDER}/all?limit=20&page=${page}`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};

const getOrder = async (numOrder) => {
  const url = `${KEYS.BACKEND_HOST_ORDER}/get-order-num/${numOrder}`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};

const changeStatus = async (body) => {
  const url = `${KEYS.BACKEND_HOST_ORDER}/change-status`;

  const headers = authHeader();

  const response = await axios.post(url, { ...body }, { headers });
  return response.data;
};

const generateBilling = async (orderId) => {
  const url = `${KEYS.BACKEND_HOST_STATE}/billing/${orderId}`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};

const generateDeliverySlip = async (orderId) => {
  const url = `${KEYS.BACKEND_HOST_STATE}/slip/${orderId}`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};

const generatePurchaseSheet = async (orderId) => {
  const url = `${KEYS.BACKEND_HOST_STATE}/purchase-sheet/${orderId}`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};

const OrderService = {
  allOrders,
  getOrder,
  generateBilling,
  generatePurchaseSheet,
  generateDeliverySlip,
  changeStatus,
};
export default OrderService;
