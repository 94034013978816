import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AddCircuitForm, CircuitList } from '../sections/@dashboard/circuit';
import Iconify from '../components/iconify/Iconify';
import BantourService from '../services/bantour-service';

function BantourPage() {
  const [value, setValue] = useState('1');

  const [showForm, setShowForm] = useState(false);

  const [circuits, setCircuits] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveData = async (data) => {
    console.log('save data', data);
    try {
      const result = await BantourService.addCircuit(data);
      console.log(result);
      setShowForm(false);
    } catch (error) {
      console.log(error);
    } finally {
      console.log('finaly');
    }
  };

  const getCircuits = async () => {
    try {
      const params = {
        page: hasNext ? page + 1 : page,
      };
      const result = await BantourService.allCircuits(params);
      console.log(result);
      if (result.code === 'SUCCESS') {
        setCircuits([...circuits, ...result.data.data]);
        setPage(result.data.page);
        setHasNext(result.data.hasNext);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setShowForm(false);
  };

  useEffect(() => {
    getCircuits();
  }, []);
  return (
    <>
      <Helmet>
        <title> Bantour | Page </title>
      </Helmet>
      <Container>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Les Circuits" value="1" />
                <Tab label="Les Excursions" value="2" />
                <Tab label="Les Réservations" value="3" />
                <Tab label="Les Confirmations" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  Liste des circuits
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setShowForm(true)}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Ajouter un circuit
                </Button>
              </Stack>
              <TableContainer>
                <CircuitList circuits={circuits} />
              </TableContainer>
            </TabPanel>
            <TabPanel value="2">Item Three</TabPanel>
            <TabPanel value="3">Item Three</TabPanel>
            <TabPanel value="4">Item Three</TabPanel>
          </TabContext>
        </Box>
      </Container>

      <Dialog
        open={showForm}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Ajouter un nouveau circuit'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <AddCircuitForm onSaveData={saveData} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default BantourPage;
