import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Checkbox, Chip, Link, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

function EditCityForm(props) {
  const [cityId, setCityId] = useState(props.data.id);
  const [name, setName] = useState('');
  const [focalPoints, setFocalPoints] = useState([...props.data.focalPoints]);
  const [districts, setDistricts] = useState([...props.data.districts]);
  const [loading, setLoading] = useState(false);
  const [dataCity, setDataCity] = useState({});

  const handleChangeDistricts = (value) => {
    setDistricts(value);
    const data = {
      cityId,
      name,
      districts: value,
      focalPoints,
    };
    setDataCity(data);
  };

  const handleChangePoints = (value) => {
    setFocalPoints(value);
    const data = {
      cityId,
      name,
      districts,
      focalPoints: value,
    };
    setDataCity(data);
  };

  const handleChangeName = (value) => {
    setName(value);
    const data = {
      cityId,
      name: value,
      districts,
      focalPoints,
    };
    setDataCity(data);
  };

  const saveData = () => {
    props.onChangeData(dataCity);
  };
  const handleClose = () => {
    props.onCloseForm();
  };

  useEffect(() => {
    const focalPoints = [];
    props.data.focalPoints?.forEach((param) => {
      focalPoints.push(param);
    });
    setFocalPoints(focalPoints);

    const districts = [];
    props.data.districts?.forEach((param) => {
      districts.push(param);
    });
    setDistricts(districts);

    setCityId(props.data.id);

    setDataCity({
      cityId: props.data.id,
      name: props.data.name,
      districts: props.data.districts,
      focalPoints: props.data.focalPoints,
    });
    setName(props.data.name);
    console.log(props.data);
  }, [props.data]);

  return (
    <>
      <Stack spacing={3}>
        <TextField
          value={name}
          name="text"
          label="Nom de la ville"
          onChange={(event) => handleChangeName(event.target.value)}
        />
        <Autocomplete
          multiple
          id="tags-filled-2"
          options={[]}
          defaultValue={[...districts]}
          freeSolo
          onChange={(event, newValue, reason) => {
            if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
              return;
            }
            handleChangeDistricts(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Entrez un point focal et valider sur entrer"
              placeholder="Entrez les points focaux de la ville"
            />
          )}
        />
        <Autocomplete
          multiple
          id="tags-filled"
          options={[]}
          defaultValue={[...focalPoints]}
          freeSolo
          onChange={(event, newValue, reason) => {
            if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
              return;
            }
            handleChangePoints(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Entrez un quartier et valider sur entrer"
              placeholder="Entrez les quartiers de la ville"
            />
          )}
        />
      </Stack>

      <Stack direction="row" spacing={1} sx={{ mt: 1.5 }} style={{ justifyContent: 'right' }}>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={saveData}>Enregistrer</Button>
      </Stack>
    </>
  );
}

export default EditCityForm;
