import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Checkbox, Chip, Collapse, Link, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import FileUploaderMultiple from '../../../components/upload/fileUploaderMultiple';

function EditCategoryForm(props) {
  const [loading, setLoading] = useState(false);
  const [showImageZone, setShowImageZone] = useState(false);
  const [images, setImages] = useState([]);
  const [values, setValues] = useState({});
  const [allCategories, setAllCategories] = useState(props.categories);
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState(props.category);
  const [categoryId, setCategoryId] = useState(props.category.id);

  const onFireImages = (imgs) => {
    console.log('Onfire image', imgs);
    setImages((images) => [...imgs]);
    setValues({ ...values, images: imgs });
  };
  const handleChangeCategories = (value) => {
    setCategories(value);
    setValues({ ...values, categories: value });
  };

  const saveData = () => {
    props.onSaveData(values);
  };
  const handleClose = () => {
    props.onCloseForm();
  };

  useEffect(() => {
    console.log(props.category);
    setValues({ ...values, name: props?.category?.name, categoryId: props.category.id });
    setAllCategories(props.categories);
  }, [props]);

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="text"
          label="Nom de la catégorie"
          value={values.name}
          onChange={(event) => setValues({ ...values, name: event.target.value })}
          placeholder="Entre le nom de la catégorie"
        />
        <div className="layout vertical">
          <Link
            underline="none"
            component="button"
            variant="body2"
            onClick={() => {
              if (showImageZone) setShowImageZone(false);
              else setShowImageZone(true);
            }}
          >
            {showImageZone && (
              <Stack className="layout horizontal center" sx={{ mb: 1 }}>
                <div className="layout horizontal center" style={{ display: 'flex', justifyItems: 'center' }}>
                  <KeyboardArrowUp />
                  <span>Cacher la zone d'image ({images.length})</span>
                </div>
              </Stack>
            )}
            {!showImageZone && (
              <Stack className="layout horizontal center">
                <div className="layout horizontal center" style={{ display: 'flex', justifyItems: 'center' }}>
                  <KeyboardArrowDown />
                  <span>Ajouter des images de la catégorie ({images.length})</span>
                </div>
              </Stack>
            )}
          </Link>
          <Collapse orientation="vertical" in={showImageZone}>
            <FileUploaderMultiple disabled={props.submitting} onFire={onFireImages} path="categories" />
          </Collapse>
        </div>
        {/* <Autocomplete
          multiple
          id="tags-categories"
          options={allCategories}
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          
          filterSelectedOptions
          
          onChange={(event, newValue, reason) => {
            if (
              event.type === 'keydown' &&
              event.key === 'Backspace' &&
              reason === 'removeOption'
            ) {
              return;
            }
            handleChangeCategories(newValue);
          }}
          renderTags={(value, getTagProps) =>
          value.map((option, index) => (
              <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
          ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Attacher à des catégories"
              placeholder="Catégories parentes"
            />
          )}
        /> */}
      </Stack>

      <Stack direction="row" spacing={1} sx={{ mt: 1.5 }} style={{ justifyContent: 'right' }}>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={saveData}>Enregistrer</Button>
      </Stack>
    </>
  );
}

export default EditCategoryForm;
