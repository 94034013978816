import React, { useEffect, useRef, useState } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.bubble.css';
// import { Key } from 'quill';
// import { MdCode, MdPhotoCamera, MdSave } from 'react-icons/md';
// import { BiCodeCurly } from 'react-icons/bi';
// import Delta from 'quill-delta';
import hljs from 'highlight.js';

// import { _getContentInEdition } from '@/helpers/shared-helper';
// import { EnumFileType, EnumStatusCode, EnumStatusResponse } from '@/types/enums/shared-enum';
// import { uploadFile } from '@/services/users.service';
// import KEYS from '@/config/key';
// import Success from '../alerts/Success';
// import FaildAlert from '../alerts/FaildAlert';
// import { Box } from '@mui/material';

function QuillEditor({ data, onSaveData, lang, entry }) {
  const [positionOfAction, setPositionOfAction] = useState(17);
  const [imgfile, uploadimg] = useState([]);
  const [contents, setContents] = useState('');
  const [hasPressEnter, setHasPressEnter] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFaild, setShowFaild] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const options = {
    theme: 'bubble',
    placeholder: `Veuillez renseigner ${entry}`,
    modules: {
      syntax: {
        highlight: (text) => hljs.highlightAuto(text).value, // Configure the syntax highlight module
      },
    },
  };

  const { quill, quillRef, Quill } = useQuill(options);

  const fileInput = useRef < HTMLInputElement > null;

  // const handleKeyDown = (range, context) => {
  //   if (range.index === context.offset) {
  //   }
  //   return false;
  // };
  // const addSomething = () => {};

  const formatFirstLine = (range, context) => {
    if (quill) {
      if (range.index > 10 && range.index === context.offset) {
        const cursorPosition = quill.getSelection()?.index ?? 0;
        // quill.formatText(0, cursorPosition, { bold: true });
        setHasPressEnter(true);
        return true;
      }
      if (range.index === context.offset) {
        return false;
      }
      setHasPressEnter(true);
      return true;
    }

    return true;
  };

  const getCurrentLine = () => {
    if (quill) {
      const selection = quill.getSelection();
      const allLines = quill.getLines();

      let indexLineSelected = -1;
      if (selection) {
        const actual = quill.getLines(selection.index, 1);
        indexLineSelected = allLines.indexOf(actual[0]);

        let newPosition = 17;
        for (let i = 0; i < indexLineSelected; i += 1) {
          newPosition += allLines[i].domNode.clientHeight;
        }

        setPositionOfAction(newPosition);
        const [currentLine] = quill.getLines(selection.index, 1);
        return currentLine;
      }
      return null;
    }
    return null;
  };

  const saveData = () => {
    console.log(quill?.getContents());
    console.log(quillRef.current.firstChild.innerHTML);
    console.log(quill?.root.innerHTML);
    const result = quill?.getContents();
    if (result) {
      const title = result.ops[0].insert;
      console.log(title);
      localStorage.setItem('editor', JSON.stringify(result));
    }
  };

  useEffect(() => {
    if (Quill && !quill) {
      const CodeBlock = Quill.import('formats/code-block');
      CodeBlock.tagName = 'PRE';
      Quill.register(CodeBlock, true);
      // register the cursor module
      // Quill.register("modules/cursors", QuillCursors);
    }
  }, [Quill]);

  useEffect(() => {
    if (quill) {
      if (data && data != null) {
        const contentWay = data.content;
        if (contentWay) quill.clipboard.dangerouslyPasteHTML(`${contentWay}`);
        else {
          const content = '<h1 dir="auto" data-placeholder="Title"> </h1>';
          quill.clipboard.dangerouslyPasteHTML(`${content}`);
        }
      } else {
        const content = '';
        quill.clipboard.dangerouslyPasteHTML(`${content}`);
      }

      const titles = quill.root.getElementsByTagName('h1');
      if (titles.length > 0) titles[0].classList.add('editor-title');

      // quill.clipboard.dangerouslyPasteHTML("<h1 className='editor-title'> </h1>");
      quill.getModule('keyboard').bindings[13].unshift({
        key: 13,
        handler: formatFirstLine,
      });

      quill.on('selection-change', (range) => {
        const currentLine = getCurrentLine();
        console.log(currentLine ? currentLine.domNode : 'No selection');
      });
      quill.on('text-change', (range) => {
        const paragraphs = quill.root.getElementsByTagName('p');
        // console.log(paragraphs);
        for (let i = 0; i < paragraphs.length; i += 1) {
          paragraphs[i].classList.add('editor-paragraph');
        }
        setContents(quill?.root.innerHTML);
        const quillContents = quill?.getContents();
        if (quillContents) {
          // const title = quillContents.ops[0].insert;
          onSaveData(quill?.root.innerHTML);
        }
        // const currentLine = getCurrentLine();
      });
      const paragraphs = quill.root.getElementsByTagName('p');
      console.log(paragraphs);
      for (let i = 0; i < paragraphs.length; i += 1) {
        paragraphs[i].classList.add('editor-paragraph');
      }
    }
  }, [quill, data, lang]);

  useEffect(() => {
    if (hasPressEnter) {
      const currentLine = getCurrentLine();
      console.log('dfdsdsfdfsdfsdfdf');
    }

    setHasPressEnter(false);
  }, [hasPressEnter]);

  return (
    <>
      <div className="bg-white rounded-lg" style={{ height: 'max-content' }}>
        <div className="p-5 ">
          <div className="w-full">
            <div
              className="grow content-title text-4xl relative"
              style={{ border: '1px solid #999', borderRadius: '10px' }}
            >
              <div className=" pl-12 relative" ref={quillRef} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default QuillEditor;
