import axios from 'axios';
import { Component } from 'react';

import KEYS from '../../helpers/keys';
import CookieService from '../../cookie-service';
// import { computeXAccessRessource } from './utils';

const upload = function (img, path, id) {
  console.log('starting upload service file:');
  const formData = new FormData();
  formData.append('path', path);
  formData.append('realfileName', '');
  formData.append('file', img);
  if (id) formData.append('id', id);
  return axios.post(`${KEYS.BACKEND_HOST_UPLOAD}/upload-image`, formData, {
    headers: authHeader(),
  });
};

const deleteImage = function (path, imageId, id) {
  console.log('starting delete image service');
  const headers = authHeader2();
  // headers['x-resource'] = btoa(computeXAccessRessource(path));
  console.log('imageId ', imageId);

  const url = `${KEYS.BACKEND_HOST_UPLOAD}/delete-image`;

  const formData = new FormData();
  formData.append('path', path);
  formData.append('filename', imageId);
  if (id) formData.append('id', id);
  return axios.post(url, formData, { headers });
};

const authHeader = function () {
  const token = CookieService.getToken();
  if (token) {
    // for Node.js Express back-end
    return {
      // 'x-access-token': token,
      'Content-Type': 'multipart/form-data',
    };
  }
  return null;
};

const authHeader2 = () => {
  const token = CookieService.getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  return {};
};

const UploadService = {
  upload,
  deleteImage,
};

export default UploadService;
