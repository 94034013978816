import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Chip,
  Stack,
  TextField,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import React, { useState } from 'react';

function AddProductCityForm(props) {
  const [productCities, setProductCities] = useState(props.productCities);
  const [allCities, setAllCities] = useState(props.newCities);
  const [values, setValues] = useState({});
  console.log(props.newCities);
  const handleChange = () => {};
  const handleChangePrice = (value) => {
    // const productCity = productCities[index];
    // productCity.purchasePrice = value;
    // const newProductCities = productCities;
    // newProductCities[index] = productCity;
    // setProductCities(newProductCities);
  };
  const handleChangeFournisseurs = (value, index) => {
    console.log(value);
    setValues({ ...values, fournisseurs: value });
    // const productCity = productCities[index];
    // productCity.fournisseurs = value;
    // const newProductCities = productCities;
    // newProductCities[index] = productCity;
    // setProductCities(newProductCities);
  };
  const saveData = () => {
    props.onSaveData(values);
  };
  const handleClose = () => {
    props.onCloseForm();
  };
  return (
    <div>
      <Stack spacing={1} direction="column">
        <Box sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label">Ville</InputLabel>
          <Select
            sx={{ width: '100%' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // value={age}
            label="Ville"
            onChange={(event) => setValues({ ...values, city: event.target.value })}
          >
            {props.newCities.map((city, index) => (
              <MenuItem value={city} key={city.id}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField
          name="text"
          label="Prix d'achat en FCFA"
          onChange={(event) => setValues({ ...values, purchasePrice: event.target.value })}
        />
        <Autocomplete
          multiple
          id="tags-filled-2"
          options={[]}
          defaultValue={[]}
          freeSolo
          onChange={(event, newValue, reason) => {
            if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
              return;
            }
            handleChangeFournisseurs(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Entrez un fournisseur et validez sur entrer"
              placeholder="nom_fournisseur#phone"
            />
          )}
        />
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mt: 1.5 }} style={{ justifyContent: 'right' }}>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={saveData}>Enregistrer</Button>
      </Stack>
    </div>
  );
}

export default AddProductCityForm;
