import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Checkbox, Chip, Collapse, Link, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FileUploaderMultiple from '../../../components/upload/fileUploaderMultiple';

function EditProductForm(props) {
  const [name, setName] = useState('');
  const [showImageZone, setShowImageZone] = useState(false);
  const [images, setImages] = useState([]);
  const [parameters, setParameters] = useState([...props.data.parameters]);

  const [values, setValues] = useState({});

  const handleChangeParameters = (value) => {
    setParameters(value);
    setValues({ ...values, parameters: value });
  };

  const onFireImages = (imgs) => {
    console.log('Onfire image', imgs);
    // setImages((images) => [...imgs]);
    // setValues({ ...values, images: imgs });
  };

  const saveData = () => {
    const updated = { ...values, name };
    props.onSaveData(updated);
  };
  const handleClose = () => {
    props.onCloseForm();
  };
  const buildProductName = () => {
    if (values.name && values.name !== '') {
      const valuesOfParameters = getValuesOfParameter();
      const name = `${values?.name?.split(' - ')[0]}${
        values.gramming !== undefined && values.gramming !== '' ? ` - ${values.gramming}` : ''
      }${valuesOfParameters && valuesOfParameters !== '' && ' - '}${valuesOfParameters}`;
      setName(name);
    } else {
      setName('');
    }
  };
  const getValuesOfParameter = () => {
    if (values.parameters && values.parameters.length > 0) {
      const valuesOfParametres = values.parameters.map((el) => {
        const data = el.split('#');
        return data.length >= 1 && data[1];
      });
      return valuesOfParametres.join(' - ');
    }
    return '';
  };
  useEffect(() => {
    buildProductName();
  }, [values]);
  useEffect(() => {
    const params = [];
    props.data.parameters?.forEach((param) => {
      params.push(param);
    });
    setParameters(params);

    if (props.data.images && props.data.images.length > 0) {
      setImages(props.data.images);
    }

    setValues({
      ...values,
      name: props.data.name,
      gramming: props.data.gramming,
      description: props.data.description,
      descriptionEng: props.data.descriptionEng,
      parameters: props.data.parameters,
      images: props.data.images,
    });
  }, [props.data]);
  return (
    <>
      <Stack spacing={3}>
        {name !== '' && <TextField name="text" label="Nom réel" disabled value={name} />}
        <TextField
          name="text"
          label="Nom du produit"
          value={values?.name?.split(' - ')[0]}
          onChange={(event) => setValues({ ...values, name: event.target.value })}
          placeholder="Entrez le nom du produit"
        />
        <TextField
          name="text"
          label="Grammage"
          value={values.gramming}
          onChange={(event) => setValues({ ...values, gramming: event.target.value })}
          placeholder="Entrez le grammage du produit"
        />
        <Autocomplete
          multiple
          id="tags-filled-2"
          options={[]}
          defaultValue={[...parameters]}
          freeSolo
          onChange={(event, newValue, reason) => {
            if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
              return;
            }
            handleChangeParameters(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Entrez les paramètres valider sur entrer"
              placeholder="Nom_paramètre#valeur_paramètre"
            />
          )}
        />
        <TextField
          id="outlined-textarea"
          label="Description du produit"
          value={values.description}
          onChange={(event) => setValues({ ...values, description: event.target.value })}
          placeholder="Entrez une description du produit"
          multiline
        />
        <TextField
          id="outlined-textarea"
          label="Description du produit en anglais"
          value={values.descriptionEng}
          onChange={(event) => setValues({ ...values, descriptionEng: event.target.value })}
          placeholder="English description"
          multiline
        />
        <div className="layout vertical">
          <Link
            underline="none"
            component="button"
            variant="body2"
            onClick={() => {
              if (showImageZone) setShowImageZone(false);
              else setShowImageZone(true);
            }}
          >
            {showImageZone && (
              <Stack className="layout horizontal center" sx={{ mb: 1 }}>
                <div className="layout horizontal center" style={{ display: 'flex', justifyItems: 'center' }}>
                  <KeyboardArrowUp />
                  <span>Cacher la zone d'image ({images?.length})</span>
                </div>
              </Stack>
            )}
            {!showImageZone && (
              <Stack className="layout horizontal center">
                <div className="layout horizontal center" style={{ display: 'flex', justifyItems: 'center' }}>
                  <KeyboardArrowDown />
                  <span>Ajouter des images du produit ({images?.length})</span>
                </div>
              </Stack>
            )}
          </Link>
          <Collapse orientation="vertical" in={showImageZone}>
            <FileUploaderMultiple
              images={values?.images}
              disabled={props.submitting}
              onFire={onFireImages}
              path="products"
            />
          </Collapse>
        </div>
        {/* <Autocomplete
          multiple
          id="tags-categories"
          options={allCategories}
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          filterSelectedOptions
          onChange={(event, newValue, reason) => {
            if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
              return;
            }
            handleChangeCategories(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField {...params} label="Sélectionnez les catégories" placeholder="Catégories du produit" />
          )}
        /> */}

        {/* <Autocomplete
          multiple
          id="tags-cities"
          options={allCities}
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          
          filterSelectedOptions
          
          onChange={(event, newValue, reason) => {
            if (
              event.type === 'keydown' &&
              event.key === 'Backspace' &&
              reason === 'removeOption'
            ) {
              return;
            }
            handleChangeCities(newValue);
          }}
          renderTags={(value, getTagProps) =>
          value.map((option, index) => (
              <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
          ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sélectionnez les villes"
              placeholder="Villes où l'on trouve le produit"
            />
          )}
        /> */}
      </Stack>

      <Stack direction="row" spacing={1} sx={{ mt: 1.5 }} style={{ justifyContent: 'right' }}>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={saveData}>Enregistrer</Button>
      </Stack>
    </>
  );
}

export default EditProductForm;
