import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, StyledEngineProvider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Send } from '@mui/icons-material';

import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { onLogin, error } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (email && email.trim().length > 0 && password && password.trim().length > 0) {
      const payload = {
        email,
        password,
      };
      setLoading(true);
      await onLogin(payload);
      setLoading(false);
    } else {
      console.log(`Incorect value`);
    }
  };

  return (
    <>
      {error && (
        <div
          className="error-message"
          style={{
            paddingBottom: '15px',
            color: 'red',
          }}
        >
          {error}
        </div>
      )}
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(event) => setEmail(event.target.value)} />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onChange={(event) => setPassword(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        endIcon={<></>}
        loading={loading}
        loadingPosition="end"
      >
        Login
      </LoadingButton>
    </>
  );
}
