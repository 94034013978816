import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import OrderService from '../services/billing-service';
import { convertToDate, fixAmount } from '../helpers/helpers';

function DetailsOrderPage() {
  const { numOrder } = useParams();
  const [order, setOrder] = useState();
  const [cart, setCart] = useState();
  const [showForm, setShowForm] = useState(false);
  const [newStatus, setNewStatus] = useState('');

  const handleClose = () => {
    setShowForm(false);
  };

  const getOrder = async () => {
    try {
      const result = await OrderService.getOrder(numOrder);
      console.log(result);
      setOrder(result.data);
      setCart(result.data.cart);
    } catch (error) {
      console.log(error);
    }
    console.log(numOrder);
  };

  const generateBilling = async (orderId) => {
    try {
      const result = await OrderService.generateBilling(orderId);
      console.log(result);
      window.open(result.data.src, '_blank');
    } catch (error) {
      console.log(error);
    }
    console.log(numOrder);
  };

  const generateDeliverySlip = async (orderId) => {
    try {
      const result = await OrderService.generateDeliverySlip(orderId);
      window.open(result.data.src, '_blank');
    } catch (error) {
      console.log(error);
    }
    console.log(numOrder);
  };

  const generatePurchaseSheet = async (orderId) => {
    try {
      const result = await OrderService.generatePurchaseSheet(orderId);
      console.log(result);
      window.open(result.data.src, '_blank');
    } catch (error) {
      console.log(error);
    }
    console.log(numOrder);
  };

  const doChangeStatus = async (orderId) => {
    try {
      if (newStatus !== 'DELIVERED' && newStatus !== 'IN_PROCESS') throw new Error('Fill correct status');
      const body = {
        status: newStatus,
        numOrder: String(order.numOrder),
      };
      const result = await OrderService.changeStatus(body);
      if (result.code === 'SUCCESS') {
        setOrder({ ...order, status: result.data.status });
      }
      handleClose();
    } catch (error) {
      console.log(error);
    }
    console.log(numOrder);
  };

  const cartAmount = (cart) => {
    const amount = cart?.products.reduce((a, b) => {
      return Number(a) + Number(b.price);
    }, 0);
    return amount;
  };

  useEffect(() => {
    getOrder();
  }, [numOrder]);
  return (
    <>
      <Helmet>
        <title> Details | Order </title>
      </Helmet>
      {order && (
        <Container>
          <Box
            sx={{
              width: '100%',
              typography: 'body1',
              bgcolor: 'white',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingTop: '20px',
              paddingBottom: '20px',
              borderRadius: '10px',
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Commande N°_</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                  {numOrder} / {order.status}
                </Typography>
              </Stack>
              {order.status !== 'DELIVERED' && order.status !== 'IN_CREATION' && (
                <Stack direction="row">
                  <Button onClick={() => setShowForm(true)}>Changer le status</Button>
                </Stack>
              )}
              <Stack direction="row">
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Commandé le : </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>{convertToDate(order.createdAt)}</Typography>
              </Stack>
            </Stack>
            <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '8px', paddingBottom: '5px' }}>
              <Typography variant="h6">Informations du client </Typography>
            </Box>
            <Stack direction="row" justifyContent="space-between">
              <div>
                <Stack direction="row">
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Noms du client: </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '400', paddingLeft: '5px' }}>
                    {order?.client?.name}
                  </Typography>
                </Stack>
                <div style={{ paddingTop: '5px' }}>
                  <Stack direction="row">
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Téléphone du client: </Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '400', paddingLeft: '5px' }}>
                      {order?.client?.phone}
                    </Typography>
                  </Stack>
                </div>
              </div>

              <Stack direction="row">
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Email : </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '400', paddingLeft: '5px' }}>
                  {order?.client?.email}
                </Typography>
              </Stack>
            </Stack>

            <Box sx={{ width: '100%', textAlign: 'center', paddingTop: '8px', paddingBottom: '5px' }}>
              <Typography variant="h6">Informations du bénéficiaire </Typography>
            </Box>
            <Stack direction="row" justifyContent="space-between">
              <div>
                <Stack direction="row">
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Noms du Béneficiaire: </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '400', paddingLeft: '5px' }}>
                    {order?.recipient?.name}
                  </Typography>
                </Stack>
                <div style={{ paddingTop: '5px' }}>
                  <Stack direction="row">
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Téléphone du Bénéficiaire: </Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '400', paddingLeft: '5px' }}>
                      {order?.recipient?.phone} / {order?.recipient?.phone2}
                    </Typography>
                  </Stack>
                </div>
              </div>

              <div>
                <Stack direction="row">
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Email : </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '400', paddingLeft: '5px' }}>
                    {order?.recipient?.email}
                  </Typography>
                </Stack>
                <div style={{ paddingTop: '5px' }}>
                  <Stack direction="row">
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Adresse bénéficiaire: </Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '400', paddingLeft: '5px' }}>
                      {order?.recipient?.neighborhood} / {order?.city?.name}
                    </Typography>
                  </Stack>
                </div>
              </div>
            </Stack>
          </Box>
          <div style={{ display: 'flex', marginTop: '10px', gap: '5px' }}>
            <Box
              sx={{
                width: '100%',
                typography: 'body1',
                bgcolor: 'white',
                padding: '10px',
                borderRadius: '10px',
              }}
            >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Num</TableCell>
                      <TableCell align="right">Produit</TableCell>
                      <TableCell align="right">Prix unitaire</TableCell>
                      <TableCell align="right">Quantité</TableCell>
                      <TableCell align="right">Prix total</TableCell>
                      <TableCell align="right">Prix achat unitaire</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cart?.products.map((product, index) => (
                      <TableRow key={product.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="right">{product.name}</TableCell>
                        <TableCell align="right">{`${product.price} ${cart?.currency}`}</TableCell>
                        <TableCell align="right">{product.quantity}</TableCell>
                        <TableCell align="right">{`${product.totalPrice} ${cart?.currency}`}</TableCell>
                        <TableCell align="right">{`${product.priceAchat} CFA`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box style={{ padding: '10px', backgroundColor: 'white', width: '300px', borderRadius: '10px' }}>
              <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Montant du panier </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    {fixAmount(cartAmount(cart))} {cart?.currency}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Montant à réduire </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>0.00 {cart?.currency}</Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Frais de services </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    {fixAmount(order.serviceFees)} {cart?.currency}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Montant total </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    {fixAmount(Number(cartAmount(cart) + Number(order.serviceFees)))} {cart?.currency}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </div>
          <Stack direction="row" spacing={4}>
            <div>
              <Stack direction="row" spacing={2} sx={{ marginBottom: '8px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Facture</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Lien vers la facture</Typography>
              </Stack>
              <Button
                sx={{ bgcolor: '#023e8a', color: 'white', fontStyle: 'normal', textTransform: 'none' }}
                onClick={() => {
                  generateBilling(order.id);
                }}
              >
                Imprimer la facture
              </Button>
            </div>
            <div>
              <Stack direction="row" spacing={2} sx={{ marginBottom: '8px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Fiche d'achat</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Lien vers la fiche d'achat</Typography>
              </Stack>
              <Button
                sx={{ bgcolor: '#023e8a', color: 'white', fontStyle: 'normal', textTransform: 'none' }}
                onClick={() => {
                  generatePurchaseSheet(order.id);
                }}
              >
                Imprimer la fiche d'achat
              </Button>
            </div>
            <div>
              <Stack direction="row" spacing={2} sx={{ marginBottom: '8px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Bordereau</Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Lien vers le bordereau</Typography>
              </Stack>
              <Button
                sx={{ bgcolor: '#023e8a', color: 'white', fontStyle: 'normal', textTransform: 'none' }}
                onClick={() => {
                  generateDeliverySlip(order.id);
                }}
              >
                Imprimer le bordereau
              </Button>
            </div>
          </Stack>
        </Container>
      )}
      <Dialog
        open={showForm}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Changer le status de la commande'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <FormControl sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel id="demo-select-small-label">Nouveau status</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={newStatus}
                label="Nouveau Status"
                onChange={(e) => setNewStatus(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'IN_PROCESS'}>Encours de traitement</MenuItem>
                <MenuItem value={'DELIVERED'}>Livrée</MenuItem>
              </Select>
              <div style={{ paddingTop: '10px' }}>
                <Stack direction="row" alignContent="end" justifyContent="end" sx={{ pt: 1 }} spacing={1}>
                  <Button
                    onClick={() => {
                      doChangeStatus();
                    }}
                    variant="contained"
                    sx={{ bgcolor: 'red', boxShadow: 'none' }}
                  >
                    Enregistrer
                  </Button>
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Annuler
                  </Button>
                </Stack>
              </div>
            </FormControl>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DetailsOrderPage;
