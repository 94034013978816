import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Chip,
  Stack,
  TextField,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

function EditProductCityForm(props) {
  const [values, setValues] = useState({});
  const [fournisseurs, setFournisseurs] = useState([...props.data.fournisseurs]);

  // const handleChangeHasQuantity = (event, hasQuantity) => {
  //   setAlignment(newAlignment);
  // };

  const handleChangeFournisseurs = (value, index) => {
    console.log(value);
    setValues({ ...values, fournisseurs: value });
  };
  const saveData = () => {
    props.onSaveData(values);
  };
  const handleClose = () => {
    props.onCloseForm();
  };

  useEffect(() => {
    const fours = [];
    props.data.fournisseurs?.forEach((fournisseur) => {
      fours.push(fournisseur);
      console.log(fournisseur);
    });
    setFournisseurs(fours);
    // setFournisseurs(props.data.fournisseurs);
    setValues({
      ...values,
      purchasePrice: props.data.purchasePrice,
      fournisseurs: props.data.fournisseurs,
      hasQuantity: props.data.hasQuantity,
      quantity: props.data.quantity,
      hasReduction: props.data.hasReduction,
      oldPrice: props.data.oldPrice,
    });
    console.log(props);
  }, [props.data]);
  return (
    <>
      {props.data ? (
        <div>
          <Stack spacing={1} direction="column">
            <TextField
              name="text"
              label="Prix d'achat en FCFA"
              value={values.purchasePrice}
              onChange={(event) => setValues({ ...values, purchasePrice: event.target.value })}
            />
            <Autocomplete
              multiple
              id="tags-filled-2"
              options={[]}
              defaultValue={[...fournisseurs]}
              freeSolo
              onChange={(event, newValue, reason) => {
                if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
                  return;
                }
                handleChangeFournisseurs(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Entrez un fournisseur et validez sur entrer"
                  placeholder="nom_fournisseur#phone"
                />
              )}
            />
            <ToggleButtonGroup
              color="primary"
              value={values.hasQuantity ? 'true' : 'false'}
              exclusive
              onChange={(event) => {
                setValues({ ...values, hasQuantity: event.target.value === 'true' });
              }}
              aria-label="Platform"
            >
              <ToggleButton value="true">Une Quantité</ToggleButton>
              <ToggleButton value="false">Pas de Quantité</ToggleButton>
              {values.hasQuantity}
            </ToggleButtonGroup>
            {values.hasQuantity && (
              <TextField
                type="number"
                defaultValue={0}
                label="Quantité"
                value={values.quantity}
                onChange={(event) => setValues({ ...values, quantity: event.target.value ?? 0 })}
              />
            )}

            <ToggleButtonGroup
              color="primary"
              value={values.hasReduction ? 'true' : 'false'}
              exclusive
              onChange={(event) => {
                setValues({ ...values, hasReduction: event.target.value === 'true' });
              }}
              aria-label="Platform"
            >
              <ToggleButton value="true">Une Reduction</ToggleButton>
              <ToggleButton value="false">Pas de Reduction</ToggleButton>
              {values.hasReduction}
            </ToggleButtonGroup>
            {values.hasReduction && (
              <TextField
                type="number"
                defaultValue={0}
                label="Ancien prix"
                value={values.oldPrice}
                onChange={(event) => setValues({ ...values, oldPrice: event.target.value ?? 0 })}
              />
            )}
          </Stack>
          <Stack direction="row" spacing={1} sx={{ mt: 1.5 }} style={{ justifyContent: 'right' }}>
            <Button onClick={handleClose}>Annuler</Button>
            <Button onClick={saveData}>Enregistrer</Button>
          </Stack>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default EditProductCityForm;
