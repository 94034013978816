import axios from 'axios';
import CookieService from '../cookie-service';
import KEYS from '../helpers/keys';

const authHeader = () => {
  const token = CookieService.getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  return {};
};

const allCities = async () => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/all-cities-admin`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};
const addCity = async (body) => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/add-city`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const updateCity = async (body) => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/update-city`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const deleteCity = async (cityId) => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/delete-city/${cityId}`;

  const headers = authHeader();

  const response = await axios.delete(url, { headers });
  return response.data;
};

const createAccount = () => {};

const CityService = {
  addCity,
  allCities,
  updateCity,
  deleteCity,
};
export default CityService;
