import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import KEYS from '../../../helpers/keys';
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

CircuitCard.propTypes = {
  circuit: PropTypes.object,
};

export default function CircuitCard({ circuit }) {
  const { minimum, price, duration, id, name, images, gramming } = circuit;

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )} */}
        <StyledProductImg
          alt={name}
          src={
            images?.length > 0
              ? `${KEYS.BACKEND_HOST_RESSOURCES}/circuits/${images[0]}`
              : `${KEYS.BACKEND_HOST_RESSOURCES}/products/masque.png`
          }
        />
      </Box>

      <Stack spacing={1} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover" href={`products/${id}`}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="column" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
              }}
            >
              Price: {price} €
            </Typography>
          </Typography>
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
              }}
            >
              Jours/nuits {duration}
            </Typography>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
