import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Checkbox, Chip, Collapse, Link, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import QuillEditor from '../../../components/editor/QuillEditor';
import FileUploaderMultiple from '../../../components/upload/fileUploaderMultiple';

function AddCircuitForm(props) {
  const [name, setName] = useState('');
  // const [focalPoints, setFocalPoints] = useState([]);
  // const [districts, setDistricts] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [dataCity, setDataCity] = useState({});
  const [showImageZone, setShowImageZone] = useState(false);
  const [images, setImages] = useState([]);
  const [services, setServices] = useState([]);
  // const [allCategories, setAllCategories] = useState([]);
  // const [categories, setCategories] = useState();

  // const [allCities, setAllCities] = useState(props.cities);
  // const [cities, setCities] = useState();

  const [values, setValues] = useState({});
  const [values2, setValues2] = useState({});
  const [itineraire, setItineraire] = useState('');
  const [description, setDescription] = useState('');
  const [depart, setDepart] = useState('');
  const [infos, setInfos] = useState('');

  // const handleChangeCategories = (value) => {
  //   console.log(values);
  //   setCategories(value);
  //   setValues({ ...values, category: value[0] });
  // };

  // const handleChangeCities = (value) => {
  //   setCities(value);
  //   setValues({ ...values, cities: value });
  // };
  const onChangeValue = (entry, value) => {
    console.log(entry);
    console.log(value);
    setValues({ ...values, [entry]: value });
  };
  const handleChangeServices = (value) => {
    setServices(value);
    setValues({ ...values, services: value });
  };

  const onFireImages = (imgs) => {
    console.log('Onfire image', imgs);
    setImages((images) => [...imgs]);
    setValues({ ...values, images: imgs });
  };

  const onSetItineraire = (data) => {
    setItineraire(data);
  };
  const onSetDescription = (data) => {
    setDescription(data);
  };
  const onSetDepart = (data) => {
    setDepart(data);
  };
  const onSetInfos = (data) => {
    setInfos(data);
  };

  const saveData = () => {
    setValues({ ...values, itineraire, description, depart, info: infos });
    const newValues = { ...values, itineraire, description, depart, info: infos };
    console.log(newValues);
    props.onSaveData(newValues);
  };
  const handleClose = () => {
    props.onCloseForm();
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="text"
          label="Nom du produit"
          onChange={(event) => {
            console.log(event.target.value);
            onChangeValue('name', event.target.value);
            // setValues({ ...values, name: event.target.value });
          }}
          placeholder="Entrez le nom du produit"
        />
        <TextField
          name="text"
          label="Minimum"
          onChange={(event) => onChangeValue('minimum', event.target.value)}
          placeholder="Entrez le minimum pax"
        />
        <TextField
          name="text"
          label="Durée"
          onChange={(event) => onChangeValue('duration', event.target.value)}
          placeholder="Entrez la durée"
        />
        <TextField
          name="text"
          label="Prix"
          onChange={(event) => onChangeValue('price', event.target.value)}
          placeholder="Entrez le prix"
        />
        <Autocomplete
          multiple
          id="tags-filled-2"
          options={[]}
          defaultValue={[]}
          freeSolo
          onChange={(event, newValue, reason) => {
            if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
              return;
            }
            handleChangeServices(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField {...params} label="Entrez les services valider sur entrer" placeholder="Nom du service" />
          )}
        />
        <div className="">
          <QuillEditor
            onSaveData={(data) => {
              console.log(values);
              onSetItineraire(data);
            }}
            entry={"l'itinéraire"}
          />
        </div>
        <div className="">
          <QuillEditor onSaveData={onSetDescription} entry={'la description'} />
        </div>
        <div className="">
          <QuillEditor onSaveData={onSetDepart} entry={'le départ'} />
        </div>
        <div className="">
          <QuillEditor onSaveData={onSetInfos} entry={'les informations complémentaires'} />
        </div>

        <div className="layout vertical">
          <Link
            underline="none"
            component="button"
            variant="body2"
            onClick={() => {
              if (showImageZone) setShowImageZone(false);
              else setShowImageZone(true);
            }}
          >
            {showImageZone && (
              <Stack className="layout horizontal center" sx={{ mb: 1 }}>
                <div className="layout horizontal center" style={{ display: 'flex', justifyItems: 'center' }}>
                  <KeyboardArrowUp />
                  <span>Cacher la zone d'image ({images.length})</span>
                </div>
              </Stack>
            )}
            {!showImageZone && (
              <Stack className="layout horizontal center">
                <div className="layout horizontal center" style={{ display: 'flex', justifyItems: 'center' }}>
                  <KeyboardArrowDown />
                  <span>Ajouter des images du produit ({images.length})</span>
                </div>
              </Stack>
            )}
          </Link>
          <Collapse orientation="vertical" in={showImageZone}>
            <FileUploaderMultiple disabled={props.submitting} onFire={onFireImages} path="circuits" />
          </Collapse>
        </div>
        {/* <Autocomplete
          multiple
          id="tags-categories"
          options={allCategories}
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          filterSelectedOptions
          onChange={(event, newValue, reason) => {
            if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
              return;
            }
            handleChangeCategories(newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />)
          }
          renderInput={(params) => (
            <TextField {...params} label="Sélectionnez les catégories" placeholder="Catégories du produit" />
          )}
        /> */}

        {/* <Autocomplete
          multiple
          id="tags-cities"
          options={allCities}
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          
          filterSelectedOptions
          
          onChange={(event, newValue, reason) => {
            if (
              event.type === 'keydown' &&
              event.key === 'Backspace' &&
              reason === 'removeOption'
            ) {
              return;
            }
            handleChangeCities(newValue);
          }}
          renderTags={(value, getTagProps) =>
          value.map((option, index) => (
              <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
          ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Sélectionnez les villes"
              placeholder="Villes où l'on trouve le produit"
            />
          )}
        /> */}
      </Stack>

      <Stack direction="row" spacing={1} sx={{ mt: 1.5 }} style={{ justifyContent: 'right' }}>
        <Button onClick={handleClose}>Annuler</Button>
        <Button onClick={saveData}>Enregistrer</Button>
      </Stack>
    </>
  );
}

export default AddCircuitForm;
