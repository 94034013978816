import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
// components
import {
  AddProductForm,
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar,
  AddProductCityForm,
} from '../sections/@dashboard/products';

// mock
import PRODUCTS from '../_mock/products';
import Iconify from '../components/iconify/Iconify';
import CityService from '../services/city-service';
import CategoryService from '../services/category-service';
import ProductService from '../services/product-service';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const [openFilter, setOpenFilter] = useState(false);

  const [showForm, setShowForm] = useState(false);

  const [cities, setCities] = useState([]);

  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);

  const [circleProgress, setCircleProgress] = useState(false);

  const [step, setStep] = useState(1);

  const [productCities, setProductCities] = useState([]);

  const [allProducts, setAllProducts] = useState([]);

  const [message, setMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleClose = () => {
    setShowForm(false);
  };

  const saveData = (data) => {
    addProducts(data);
  };

  const saveProductCities = (data) => {
    addProductCities(data);
  };

  const getAllCities = async () => {
    await CityService.allCities()
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            console.log(result.data);
            const data = result.data;
            const formatCities = data.map((element) => {
              return {
                name: element.name,
                cityId: element.cityId,
              };
            });
            console.log(formatCities);
            setCities(formatCities);
          }
        },
        (error) => {}
      )
      .finally(() => {});
  };

  const getAllCategories = async () => {
    await CategoryService.allCategories()
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            console.log(result.data);
            const data = result.data;
            const formatCategories = data.map((element) => {
              return {
                name: element.name,
                categoryId: element.id,
              };
            });
            console.log(formatCategories);
            setCategories(formatCategories);
          }
        },
        (error) => {}
      )
      .finally(() => {});
  };

  const addProducts = async (data) => {
    setLoading(true);
    console.log(data);
    await ProductService.addProduct(data)
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            // setProductCities(result.data);
            setAllProducts([result.data, ...allProducts]);
            setMessage("Le produit a bien été ajouté, veuillez l'insérer dans une ville");
            setHasSuccess(true);
            // setStep(2);
          }
        },
        (error) => {
          console.log(error);
          if (error.response && error.response.data.statusCode === 'PRODUCT_ALREADY_EXIST') {
            // show alert error
            // console.log('Un produit existe déjà avec ce nom');
            setMessage('Un produit existe déjà avec ce nom');
            setHasError(true);
          }
        }
      )
      .finally(() => {
        setLoading(false);
        setShowForm(false);
      });
  };
  const addProductCities = async (data) => {
    setLoading(true);
    const body = {
      productCities: data,
    };
    await ProductService.addProductCities(body)
      .then((result) => {
        if (result.code === 'SUCCESS') {
          setShowForm(false);
          setStep(1);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllProducts = async () => {
    setLoading(true);
    await ProductService.allProducts()
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            console.log(result);
            setAllProducts(result.data.data);
            setHasNext(result.data.hasNext);
            setTotalDocs(result.data.total_size);
            if (result.data.hasNext) setPage(result.data.page + 1);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const getMoreProducts = async () => {
    try {
      if (hasNext) {
        setLoading(true);
        const result = await ProductService.allProducts(page);
        if (result.code === 'SUCCESS') {
          console.log(result);
          setAllProducts([...allProducts, ...result.data.data]);
          setHasNext(result.data.hasNext);
          setPage(result.data.page + 1);
          // setAllProducts(result.data.data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const doSetUpercase = async () => {
    try {
      setLoading(true);
      const result = await ProductService.setAllInUpercase();
      if (result.code === 'SUCCESS') {
        console.log('success');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCities();
    getAllCategories();
    getAllProducts();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard: Products | Admin Ebantou</title>
      </Helmet>

      <Container>
        {hasError && (
          <Alert
            severity="error"
            onClose={() => {
              setHasError(false);
            }}
          >
            <AlertTitle>Error</AlertTitle>
            {message} — <strong>sorry!</strong>
          </Alert>
        )}
        {hasSuccess && (
          <Alert
            severity="success"
            onClose={() => {
              setHasSuccess(false);
            }}
          >
            <AlertTitle>Success</AlertTitle>
            {message} — <strong>good!</strong>
          </Alert>
        )}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Liste des produits: {totalDocs}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setShowForm(true);
              setHasError(false);
              setHasSuccess(false);
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Ajouter un produit
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              doSetUpercase();
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Mettre tout en majuscule
          </Button>
        </Stack>
        {loading && circleProgress === false && <LinearProgress />}
        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack>

        <ProductList products={allProducts} />
        {hasNext && (
          <div className="" style={{ textAlign: 'center', paddingTop: '20px' }}>
            <Button variant="contained" onClick={() => getMoreProducts()}>
              Load More
            </Button>
          </div>
        )}
        <ProductCartWidget />
      </Container>

      <Dialog
        open={showForm}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Ajouter un nouveau produit'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            {step === 1 && <AddProductForm cities={cities} categories={categories} onSaveData={saveData} />}
            {step === 2 && <AddProductCityForm productCities={productCities} onSaveData={saveProductCities} />}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
