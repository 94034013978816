import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  LinearProgress,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
} from '@mui/material';

import UserService from '../services/user-service';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserEntry, UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [isDialogAddUserOpen, setIsDialogAddUserOpen] = useState(false);

  const [isDialogChangeRoleOpen, setIsDialogChangeRoleOpen] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [userList, setUserList] = useState([]);

  const [totalSize, setTotalSize] = useState(0);

  const [loading, setLoading] = useState(false);

  const [circleProgress, setCircleProgress] = useState(true);

  const [filterList, setFilterList] = useState({ createdAt: -1 });

  const [selectedUser, setSelectedUser] = useState();

  const [newRole, setNewRole] = useState();

  const allRoles = [
    { label: 'Admin', value: 'ADMIN' },
    { label: 'Client', value: 'CLIENT' },
    { label: 'Agent', value: 'AGENT' },
    { label: 'Gérant', value: 'GERANT' },
    { label: 'Livreur', value: 'LIVREUR' },
  ];

  const handleClose = () => {
    setIsDialogAddUserOpen(false);
    setIsDialogChangeRoleOpen(false);
    // setOpen(null);
  };
  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setSelectedUser(row);
    console.log(userList);
    console.log(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const getAllUsers = async (params) => {
    const newParams =
      params !== undefined
        ? params
        : {
            filter: filterList,
            page_num: 1,
            page_size: rowsPerPage,
          };
    setLoading(true);
    await UserService.allUsers(newParams)
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const data = result.data;
            const users = data.data;

            setUserList(users);
            setRowsPerPage(data.page_size);
            setTotalSize(data.total_size);
          }
          setCircleProgress(false);
        },
        (error) => {
          console.log(error);
          setCircleProgress(false);
        }
      )
      .finally(() => {
        setLoading(false);
        setCircleProgress(false);
      });
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const newParams = {
      filter: filterList,
      page_num: newPage + 1,
      page_size: rowsPerPage,
    };
    getAllUsers(newParams);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const doSaveUser = async (data) => {
    try {
      setLoading(true);
      console.log(`dans doSaveUser data= ${data}`);
      const result = await UserService.createAccount(data);
      console.log(`dans doSaveUser result=, ${result}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setIsDialogAddUserOpen(false);
    }
  };

  const doChangeRoleUser = async () => {
    try {
      setLoading(true);
      const params = {
        userId: selectedUser.id,
        role: newRole,
      };
      const result = await UserService.changeRoleUser(params);
      console.log(result);
      if (result.code === 'SUCCESS') {
        const users = userList.map((user) => {
          if (user.id === result.data.id) {
            user.role = newRole;
          }
          return user;
        });
        setUserList(users);
      }
      setIsDialogChangeRoleOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const doChangeStatusUser = async (status) => {
    try {
      setLoading(true);
      const params = {
        userId: selectedUser.id,
        status,
      };
      const result = await UserService.changeStatusUser(params);
      console.log(result);
      if (result.code === 'SUCCESS') {
        const users = userList.map((user) => {
          if (user.id === result.data.id) {
            user.isEnabled = status === 'enabled';
          }
          return user;
        });
        setUserList(users);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  // const filteredUsers = userList;

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> User | List </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Utilisateur
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setIsDialogAddUserOpen(true)}
          >
            Ajouter un utilisateur
          </Button>
        </Stack>
        {loading && circleProgress === false && <LinearProgress />}

        {circleProgress ? (
          <CircularProgress />
        ) : (
          <Card>
            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={totalSize}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {userList.map((row) => {
                      const { userId, name, role, isActivate, avatarUrl, isVerified, isEnabled, email } = row;

                      const selectedUser = selected.indexOf(name) !== -1;

                      return (
                        <TableRow hover key={userId} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={avatarUrl} />
                              <Stack direction="column">
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                                <Typography variant="subtitle2" noWrap style={{ fontSize: '12px', color: '#555' }}>
                                  {email}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{sentenceCase(role ?? '')}</TableCell>

                          <TableCell align="left">{isActivate ? 'Yes' : 'No'}</TableCell>

                          <TableCell align="left">
                            {isEnabled ? (
                              <Label color={(isEnabled === false && 'error') || 'success'}>Activé</Label>
                            ) : (
                              <Label color="error">Non Activé</Label>
                            )}
                          </TableCell>

                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalSize}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleCloseMenu();
            setIsDialogChangeRoleOpen(true);
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Changer le rôle
        </MenuItem>

        {selectedUser && selectedUser?.isEnabled === true ? (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={() => {
              handleCloseMenu();
              doChangeStatusUser('disabled');
            }}
          >
            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
            Désactiver
          </MenuItem>
        ) : (
          <MenuItem
            sx={{ color: 'success.main' }}
            onClick={() => {
              handleCloseMenu();
              doChangeStatusUser('enabled');
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Activer
          </MenuItem>
        )}
      </Popover>

      <Dialog
        open={isDialogAddUserOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Ajouter un utilisateur'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <UserEntry
              onSaveData={(data) => {
                doSaveUser(data);
              }}
              onClose={() => handleClose()}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDialogChangeRoleOpen && selectedUser}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{`Changer le rôle de ${selectedUser?.name}`}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description" sx={{ width: '100%' }}>
            <TextField
              id="outlined-select-currency-native"
              select
              label="Nouveau rôle"
              defaultValue={sentenceCase(selectedUser?.role ?? 'CLIENT')}
              SelectProps={{
                native: true,
              }}
              sx={{ width: '100%' }}
              onChange={(event) => setNewRole(event.target.value)}
            >
              {allRoles.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </DialogContentText>
          <div>
            <Stack direction="row" alignContent="end" justifyContent="end" sx={{ pt: 1 }} spacing={1}>
              <Button
                onClick={() => {
                  doChangeRoleUser();
                }}
                variant="contained"
                sx={{ boxShadow: 'none' }}
              >
                Enregistrer
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Annuler
              </Button>
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
