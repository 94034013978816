import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import OrderService from '../services/billing-service';
import { convertToDate, fixAmount } from '../helpers/helpers';

function BillingPage() {
  const [value, setValue] = React.useState('1');
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllOrders = async () => {
    try {
      const result = await OrderService.allOrders(page);
      setOrders([...orders, ...result.data.data]);
      setHasNext(result.data.hasNext);
      setPage(result.data.hasNext ? page + 1 : result.data.page);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  return (
    <>
      <Helmet>
        <title> Billing | Page </title>
      </Helmet>
      <Container>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Liste des commande" value="1" />
                <Tab label="Bordereaux" value="2" />
                <Tab label="Factures" value="3" />
                <Tab label="Fiches d'achat" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Num Commande</TableCell>
                      <TableCell align="right">Date Commande</TableCell>
                      <TableCell align="right">Prix total</TableCell>
                      <TableCell align="right">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((order) => (
                      <TableRow
                        key={order.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          backgroundColor: `${
                            order.status === 'DELIVERED'
                              ? 'black'
                              : order.status === 'IN_PROCESS'
                              ? 'orange'
                              : order.status === 'CANCELED'
                              ? 'red'
                              : order.status === 'COMPLETED'
                              ? 'green'
                              : 'white'
                          }`,
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: `${order.status === 'IN_CREATION' ? 'black' : 'white'}` }}
                        >
                          <Link to={`${order.numOrder}`}> {order.numOrder}</Link>
                        </TableCell>
                        <TableCell align="right" sx={{ color: `${order.status === 'IN_CREATION' ? '' : 'white'}` }}>
                          {convertToDate(order.createdAt)}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: `${order.status === 'IN_CREATION' ? 'black' : 'white'}` }}
                        >
                          {fixAmount(order.totalPrice)} {order.cart?.currency}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ color: `${order.status === 'IN_CREATION' ? 'black' : 'white'}` }}
                        >
                          {order.status}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {hasNext && <Button onClick={() => getAllOrders()}>Load More</Button>}
              </TableContainer>
            </TabPanel>
            <TabPanel value="2">Item Three</TabPanel>
            <TabPanel value="3">Item Three</TabPanel>
            <TabPanel value="4">Item Three</TabPanel>
          </TabContext>
        </Box>
      </Container>
    </>
  );
}

export default BillingPage;
