import KEYS from "./helpers/keys";

const getUserLocal = () => {
    return localStorage.getItem("user") != null
        ? JSON.parse(localStorage.getItem("user"))
        : null;
};

const saveUserLocal = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
};
const removeUserLocal = () => {
    localStorage.removeItem("user");
};

const getLanguageLocal = () => {
    return localStorage.getItem(`${KEYS.USER_LOCALE}`) != null
        ? localStorage.getItem(`${KEYS.USER_LOCALE}`)
        : null;
};

const LocalStorageService={
    getUserLocal,
    saveUserLocal,
    removeUserLocal,
    getLanguageLocal
}

export default  LocalStorageService;