import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import Iconify from '../components/iconify';

import ProductService from '../services/product-service';
import KEYS from '../helpers/keys';
import { AddProductCityForm, EditProductCityForm, EditProductForm } from '../sections/@dashboard/products';
import CategoryService from '../services/category-service';

function DetailsProductPage() {
  const [product, setProduct] = useState();
  const [productCities, setProductCities] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [params, setParams] = useState([]);
  const [values, setValues] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showFormEdit, setShowFormEdit] = useState(false);
  const [showEditProduct, setShowEditProduct] = useState();
  const [showFormChangeCategory, setShowFormChangeCategory] = useState();
  const [showConfirmDelete, setShowConfirmDelete] = useState();
  const [cityList, setCityList] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectProductCity, setSelectProductCity] = useState();
  const [selectCategory, setSelectCategory] = useState();

  const [newCategory, setNewCategory] = useState();

  const { id } = useParams();
  const [productId, setProductId] = useState(id);

  const StyledProductImg = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
  });
  const handleClose = () => {
    setShowForm(false);
    setShowFormEdit(false);
    setShowEditProduct(false);
    setShowFormChangeCategory(false);
    setShowConfirmDelete(false);
  };

  const getAllNewCities = async () => {
    // id
    await ProductService.getOrderCities(productId)
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const cities = result.data;
            setCityList(cities);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {});
  };

  const saveProductCities = async (data) => {
    console.log(data);
    const body = {
      productId, // id
      cityId: data.city.id,
      purchasePrice: data.purchasePrice,
      fournisseurs: data.fournisseurs,
    };
    setShowForm(false);
    try {
      const result = await ProductService.addProductCity(body);
      console.log(result);
      if (result.code === 'SUCCESS') {
        const productCity = {
          ...result.data,
        };
        setProductCities([...productCities, productCity]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateProductCity = async (data) => {
    const body = {
      productCityId: selectProductCity.id,
      purchasePrice: data.purchasePrice,
      fournisseurs: data.fournisseurs,
      hasQuantity: data.hasQuantity,
      quantity: `${data.quantity}` ?? '',
      hasReduction: data.hasReduction,
      oldPrice: `${data.oldPrice}` ?? '',
    };
    setShowFormEdit(false);
    try {
      const result = await ProductService.updateProductCity(body);
      console.log(result);
      if (result.code === 'SUCCESS') {
        const newProductsCities = productCities.map((p) => {
          if (p.id === result.data.id) {
            const pcity = {
              ...result.data,
              city: p.city,
            };
            return pcity;
          }
          return p;
        });
        setProductCities(newProductsCities);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateProduct = async (data) => {
    console.log(data);

    const body = {
      productId: product.id,
      name: data.name,
      gramming: data.gramming,
      description: data.description,
      descriptionEng: data.descriptionEng,
      parameters: data.parameters,
    };
    setShowEditProduct(false);
    try {
      const result = await ProductService.updateProduct(body);
      console.log(result);
      if (result.code === 'SUCCESS') {
        setProduct(result.data.product);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const changeStatutProductCity = async (id, status) => {
    const body = {
      productCityId: id,
      status,
    };
    setShowFormEdit(false);
    try {
      const result = await ProductService.changeStatutProductCity(body);
      // console.log(result);
      if (result.code === 'SUCCESS') {
        console.log(result);
        const newProductCities = productCities.map((p) => {
          if (p.id === id) {
            return {
              ...p,
              isDeleted: result.data.isDeleted,
              isEanable: result.data.isEanable,
            };
          }
          return p;
        });
        setProductCities(newProductCities);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProduct = async () => {
    try {
      const result = await ProductService.getProductById(productId);
      console.log(result);
      if (result.code === 'SUCCESS') {
        setProduct(result.data.product);
        setProductCities(result.data.productCities);
        setParameters(result.data.product.parameters);
        const params = [];
        const values = [];
        result.data.product.parameters?.forEach((el) => {
          params.push(el.split('#')[0]);
          values.push(el.split('#')[1]);
        });
        setParams(params);
        setValues(values);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCategories = async () => {
    await CategoryService.allCategories()
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            console.log(result.data);
            const data = result.data;
            const formatCategories = data.map((element) => {
              return {
                name: element.name,
                categoryId: element.id,
              };
            });
            console.log(formatCategories);
            setAllCategories(formatCategories);
          }
        },
        (error) => {}
      )
      .finally(() => {});
  };

  const doChangeCategory = async () => {
    if (newCategory) {
      try {
        const params = {
          categoryId: newCategory?.categoryId,
          productId,
        };
        const result = await ProductService.changeCategory(params);
        if (result.code === 'SUCCESS') {
          console.log(result);
          getProduct();
          setNewCategory(null);
        }
      } catch (error) {
        console.log(error);
      } finally {
        handleClose();
      }
    } else {
      console.log('selectionne la category');
    }
  };

  const doDeleteProduct = async () => {
    try {
      const result = await ProductService.deleteProduct(productId);
      if (result.code === 'SUCCESS') {
        console.log(result);
        getProduct();
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleClose();
    }
  };

  const getNextProduct = async () => {
    try {
      setProduct(null);
      const result = await ProductService.getNextProduct(product.createdAt);
      console.log(result);
      if (result.code === 'SUCCESS') {
        setProduct(result.data.product);
        console.log(`nextproduct = ${result.data.product.id}`);
        setProductId(result.data.product.id);

        const url = `${window.location.origin}/dashboard/products/${result.data.product.id}`;
        console.log(`newurl = ${url}`);
        window.location.assign(url);
        setProductCities(result.data.productCities);
        setParameters(result.data.product.parameters);
        const params = [];
        const values = [];
        result.data.product.parameters?.forEach((el) => {
          params.push(el.split('#')[0]);
          values.push(el.split('#')[1]);
        });
        setParams(params);
        setValues(values);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getPreviousProduct = async () => {
    try {
      const result = await ProductService.getPreviousProduct(product.createdAt);
      console.log(result);
      if (result.code === 'SUCCESS') {
        setProduct(result.data.product);
        setProductCities(result.data.productCities);
        setParameters(result.data.product.parameters);
        const params = [];
        const values = [];
        result.data.product.parameters?.forEach((el) => {
          params.push(el.split('#')[0]);
          values.push(el.split('#')[1]);
        });
        setParams(params);
        setValues(values);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeCategories = (value) => {
    console.log(value);
    // setCategories(value);
    setSelectCategory(value[0]);
    setNewCategory(value[0]);
    // setValues({ ...values, 'category': value[0] });
  };

  useEffect(() => {
    getProduct();
    getAllNewCities();
    getAllCategories();
  }, [productId]);
  return (
    <>
      <Helmet>
        <title> Product | Detail </title>
      </Helmet>
      {product && (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Stack direction="column">
              <Typography variant="h6" gutterBottom>
                Détails du produit
              </Typography>
              {product.isDeleted === true && (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ textTransform: 'none', color: 'red', boxShadow: 'none', fontWeight: 'normal' }}
                >
                  Ce produit a été supprimé
                </Typography>
              )}
            </Stack>
            <Stack direction="row" className="" spacing={1}>
              <Button
                startIcon={<Iconify icon="eva:arrow-ios-back-outline" />}
                style={{ textTransform: 'none' }}
                onClick={() => getPreviousProduct()}
              >
                Previous
              </Button>
              <Button
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                style={{ textTransform: 'none' }}
                onClick={() => getNextProduct()}
              >
                Next
              </Button>

              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                style={{ textTransform: 'none', backgroundColor: '#ff5400', boxShadow: 'none' }}
                onClick={() => setShowFormChangeCategory(true)}
                disabled={product.isDeleted}
              >
                Changer la categorie
              </Button>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                style={{ textTransform: 'none', boxShadow: 'none' }}
                onClick={() => setShowEditProduct(true)}
                disabled={product.isDeleted}
              >
                Editer le produit
              </Button>
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                style={{ textTransform: 'none', backgroundColor: 'red', boxShadow: 'none' }}
                onClick={() => setShowConfirmDelete(true)}
                disabled={product.isDeleted}
              >
                Supprimer définitivement
              </Button>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="top" spacing={2} mb={5}>
            <Box sx={{ minWidth: '250px', position: 'relative' }}>
              <img
                alt={product.name}
                src={
                  product.images.length > 0
                    ? `${KEYS.BACKEND_HOST_RESSOURCES}/products/${product.images[0]}`
                    : `${KEYS.BACKEND_HOST_RESSOURCES}/products/masque.png`
                }
              />
            </Box>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="h6" gutterBottom>
                {product.name}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {product.gramming}
              </Typography>
              <Stack direction="row" spacing={1}>
                <Typography variant="h6" sx={{ fontWeight: 'normal', fontSize: '0.95em' }}>
                  Catégorie:{' '}
                </Typography>
                <Typography variant="h6"> {product.category.name}</Typography>
              </Stack>
              <Typography sx={{ fontWeight: 'normal', fontSize: '0.95em' }}>{product.description}</Typography>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {params.map((param, index) => (
                        <TableCell key={index}>{param}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      {values.map((value, index) => (
                        <TableCell component="th" scope="row" key={index}>
                          {' '}
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h6" gutterBottom>
              Villes du produit
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              style={{ textTransform: 'none' }}
              onClick={() => setShowForm(true)}
              disabled={product.isDeleted}
            >
              Attacher à une ville
            </Button>
          </Stack>
          <Box>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Ville</TableCell>
                    <TableCell>Prix d'achat</TableCell>
                    <TableCell>Prix de vente</TableCell>
                    <TableCell>Fournisseurs</TableCell>
                    <TableCell>Quantité</TableCell>
                    <TableCell>Definir une quantité</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productCities.map((productCity, index) => (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={productCity.id}>
                      <TableCell component="th" scope="row">
                        {productCity.city.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {productCity.purchasePrice} FCFA
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {productCity.sellingPrice} FCFA
                      </TableCell>
                      <TableCell component="th" scope="row" sx={{ maxWidth: '250px' }}>
                        <Stack direction="column">
                          {productCity.fournisseurs &&
                            productCity.fournisseurs.map((fournisseur, i) => <span key={i}>{fournisseur}</span>)}
                        </Stack>
                      </TableCell>
                      <TableCell>{productCity.quantity}</TableCell>
                      <TableCell>{productCity.hasQuantity ? 'Oui' : 'Non'}</TableCell>
                      <TableCell component="th" scope="row">
                        <Stack direction="row">
                          <Button
                            onClick={() => {
                              setSelectProductCity(productCity);
                              setShowFormEdit(true);
                            }}
                            disabled={product.isDeleted}
                          >
                            Editer
                          </Button>
                          {productCity.isDeleted === true ? (
                            <Button
                              style={{ color: 'green' }}
                              onClick={() => {
                                changeStatutProductCity(productCity.id, 'eanable');
                              }}
                              disabled={product.isDeleted}
                            >
                              Activer
                            </Button>
                          ) : (
                            <Button
                              style={{ color: 'red' }}
                              onClick={() => {
                                changeStatutProductCity(productCity.id, 'disable');
                              }}
                              disabled={product.isDeleted}
                            >
                              Désactiver
                            </Button>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      )}
      <Dialog
        open={showFormChangeCategory}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Changer de Categorie'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <Autocomplete
              multiple
              id="tags-categories"
              options={allCategories}
              getOptionLabel={(option) => option.name}
              defaultValue={[]}
              filterSelectedOptions
              onChange={(event, newValue, reason) => {
                if (event.type === 'keydown' && event.key === 'Backspace' && reason === 'removeOption') {
                  return;
                }
                handleChangeCategories(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Sélectionnez la catégorie" placeholder="Catégorie du produit" />
              )}
            />
            <div>
              <Stack direction="row" alignContent="end" justifyContent="end" sx={{ pt: 1 }} spacing={1}>
                <Button
                  onClick={() => {
                    doChangeCategory();
                  }}
                  variant="contained"
                  sx={{ boxShadow: 'none' }}
                >
                  Enregistrer
                </Button>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Annuler
                </Button>
              </Stack>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showConfirmDelete}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Confirmer la suppression'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              <Typography variant="h6">Voulez vous vraiment supprimer ce produit?</Typography>
            </div>
            <div>
              <Stack direction="row" alignContent="end" justifyContent="end" sx={{ pt: 1 }} spacing={1}>
                <Button
                  onClick={() => {
                    doDeleteProduct();
                  }}
                  variant="contained"
                  sx={{ bgcolor: 'red', boxShadow: 'none' }}
                >
                  Supprimer
                </Button>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Annuler
                </Button>
              </Stack>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showForm}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Attacher à une ville'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <AddProductCityForm
              onSaveData={saveProductCities}
              newCities={cityList}
              onCloseForm={() => setShowForm(false)}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showFormEdit}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Editer le produit de la ville'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            {selectProductCity ? (
              <EditProductCityForm
                onSaveData={updateProductCity}
                newCities={cityList}
                data={selectProductCity}
                onCloseForm={() => setShowFormEdit(false)}
              />
            ) : (
              <></>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showEditProduct}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Editer le produit'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            {product ? (
              <EditProductForm
                onSaveData={updateProduct}
                data={product}
                onCloseForm={() => setShowEditProduct(false)}
              />
            ) : (
              <></>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default DetailsProductPage;
