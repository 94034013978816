import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { PropTypes } from 'prop-types';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import useAuth from './hooks/useAuth';
import CityPage from './pages/CityPage';
import CategoryPage from './pages/CategoryPage';
import DetailsProductPage from './pages/DetailsProductPage';
import BillingPage from './pages/BillingPage';
import DetailsOrderPage from './pages/DetailsOrderPage';
import BantourPage from './pages/BantourPage';

// ----------------------------------------------------------------------

const ProtectedRoute = ({ children }) => {
  const { token } = useAuth();
  const location = useLocation();
  console.log(token);
  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.any,
};

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'city', element: <CityPage /> },
        { path: 'category', element: <CategoryPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'billing', element: <BillingPage /> },
        { path: 'products/:id', element: <DetailsProductPage /> },
        { path: 'billing/:numOrder', element: <DetailsOrderPage /> },
        { path: 'bantour', element: <BantourPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
