import React from 'react';
import { PropTypes } from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../hooks/useAuth';
import AuthService from '../services/auth-service';
import CookieService from '../cookie-service';
import LocalStorageService from '../local-storage-service';

export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [token, setToken] = React.useState(CookieService.getToken());
  const [error, setError] = React.useState(null);
  const location = useLocation();

  const handleLogin = async (payload) => {
    setError(null);
    AuthService.login(payload).then(
      (result) => {
        CookieService.saveToken(result.data.accessToken.token);
        LocalStorageService.saveUserLocal(result.data.payload);
        setToken(result.data.accessToken.token);
        const origin = location.state?.from?.pathname || '/dashboard';
        navigate(origin);
      },
      (err) => {
        console.log(err);
        const message = err.response.data.message;
        setError(message);
      }
    );
  };

  const handleLogout = () => {
    setToken(null);
    CookieService.removeToken();
    LocalStorageService.removeUserLocal();
  };

  const value = {
    token,
    error,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
AuthProvider.propTypes = {
  children: PropTypes.any,
};
