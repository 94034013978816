import cookie from 'react-cookies';

const getToken = () => {
  return cookie.load('token');
};
const saveToken = (token) => {
  cookie.save('token', token, { path: '/' });
};
const getRefreshToken = ()=>{
  return cookie.load('refresh-token')
};
const removeToken = ()=>{
  cookie.remove("token");
}
const removeRefreshToken = ()=>{
  cookie.remove("refresh-token");
}

const CookieService = {
  getToken,
  saveToken,
  getRefreshToken,
  removeToken,
  removeRefreshToken
}
export default CookieService;
