import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// ----------------------------------------------------------------------

export default function UserEntry(props) {
  const [values, setValues] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const saveData = () => {
    console.log(values);
    props.onSaveData(values);
  };
  return (
    <Box>
      <Stack direction="column" spacing={1.5}>
        <TextField
          name="text"
          label="Nom d'utilisateur"
          onChange={(event) => setValues({ ...values, name: event.target.value })}
        />
        <TextField
          name="text"
          label="Email de l'utilisateur"
          onChange={(event) => setValues({ ...values, email: event.target.value })}
        />
        <FormControl sx={{}} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(event) => setValues({ ...values, password: event.target.value })}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
      </Stack>
      <div>
        <Stack direction="row" sx={{ pt: 2 }} spacing={1}>
          <Button variant="contained" type="button" onClick={() => saveData()}>
            Enregistrer
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              props.onClose();
            }}
          >
            Annuler
          </Button>
        </Stack>
      </div>
    </Box>
  );
}
