const BACKEND_API = process.env.REACT_APP_BACKEND_API;
const BACKEND_HOST_AUTH = `${BACKEND_API}${process.env.REACT_APP_BACKEND_HOST_AUTH}`;
const BACKEND_HOST_USER = `${BACKEND_API}${process.env.REACT_APP_BACKEND_HOST_USER}`;
const BACKEND_HOST_STRUCTURE = `${BACKEND_API}${process.env.REACT_APP_BACKEND_HOST_STRUCTURE}`;
const BACKEND_HOST_RESSOURCES = `${BACKEND_API}${process.env.REACT_APP_BACKEND_RESSOURCES}`;
const BACKEND_HOST_UPLOAD = `${BACKEND_API}${process.env.REACT_APP_BACKEND_UPLOAD}`;
const BACKEND_HOST_PRODUCT = `${BACKEND_API}${process.env.REACT_APP_BACKEND_PRODUCT}`;
const BACKEND_HOST_ORDER = `${BACKEND_API}${process.env.REACT_APP_BACKEND_ORDER}`;
const BACKEND_HOST_STATE = `${BACKEND_API}${process.env.REACT_APP_BACKEND_STATE}`;
const BACKEND_HOST_BANTOUR = `${BACKEND_API}${process.env.REACT_APP_BACKEND_HOST_BANTOUR}`;

const LOCAL_LIMIT = 100;

const USER_LOCALE = process.env.REACT_APP_USER_LOCALE;

const KEYS = {
  BACKEND_HOST_AUTH,
  BACKEND_HOST_USER,
  BACKEND_HOST_STRUCTURE,
  USER_LOCALE,
  BACKEND_HOST_RESSOURCES,
  BACKEND_HOST_UPLOAD,
  BACKEND_HOST_PRODUCT,
  BACKEND_HOST_ORDER,
  BACKEND_HOST_STATE,
  LOCAL_LIMIT,
  BACKEND_HOST_BANTOUR,
};

export default KEYS;
