import axios from 'axios';
import CookieService from '../cookie-service';
import KEYS from '../helpers/keys';

const authHeader = () => {
  const token = CookieService.getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  return {};
};

const allProducts = async (page = 1) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/all-admin?page_num=${page}&page_size=${KEYS.LOCAL_LIMIT}`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};
const addProduct = async (body) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/add`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const addProductCities = async (body) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/update-many-cities`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const addProductCity = async (body) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/add-product-city`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const updateProductCity = async (body) => {
  if (!body.hasQuantity) {
    const { quantity, ...newBody } = body;
    body = newBody;
  }
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/update-product-city/${body.productCityId}`;

  const headers = authHeader();

  const response = await axios.put(url, body, { headers });
  return response.data;
};

const updateProduct = async (body) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/update-product/${body.productId}`;

  const headers = authHeader();

  const response = await axios.put(url, body, { headers });
  return response.data;
};

const getProductById = async (id) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/get-product-admin/${id}`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};

const getOrderCities = async (id) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/order-cities/${id}`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};
const changeStatutProductCity = async (body) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/change-statut`;

  const headers = authHeader();

  const response = await axios.post(url, { ...body }, { headers });
  return response.data;
};
const changeCategory = async (params) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/change-category/${params.productId}`;
  const headers = authHeader();
  const body = { categoryId: params.categoryId };

  const response = await axios.put(url, { ...body }, { headers });
  return response.data;
};

const deleteProduct = async (id) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/delete/${id}`;
  const headers = authHeader();

  const response = await axios.delete(url, { headers });
  return response.data;
};

const getNextProduct = async (createdAt) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/next-product/${createdAt}`;
  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};

const getPreviousProduct = async (createdAt) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/previous-product/${createdAt}`;
  const headers = authHeader();

  const response = await axios.get(url, { headers });
  return response.data;
};

const searchProduct = async (params) => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/search-product-admin?search=${params.search}`;
  const response = await axios.get(url, {
    params: {
      page: params?.page ?? 1,
      limit: KEYS.LOCAL_LIMIT ?? 10,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const setAllInUpercase = async () => {
  const url = `${KEYS.BACKEND_HOST_PRODUCT}/all-upercase`;
  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const ProductService = {
  addProduct,
  allProducts,
  addProductCities,
  getProductById,
  getOrderCities,
  addProductCity,
  updateProductCity,
  changeStatutProductCity,
  updateProduct,
  changeCategory,
  deleteProduct,
  searchProduct,
  getNextProduct,
  getPreviousProduct,
  setAllInUpercase,
};
export default ProductService;
