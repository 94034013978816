const cancelEventPropagation = (event) => {
  if (event == null) return;
  event.preventDefault();
  if (event.stopPropagation) {
    event.stopPropagation();
  } else if (window.event) {
    window.event.cancelBubble = true;
  }
};
const convertToDate = (value) => {
  return new Intl.DateTimeFormat('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(value);
};

const fixAmount = (amount) => {
  const price = Number(amount);
  return price.toFixed(2);
};
export { cancelEventPropagation, convertToDate, fixAmount };
