import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  LinearProgress,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  Box,
  InputLabel,
} from '@mui/material';

import {
  AddCategoryForm,
  CategoryListHead,
  CategoryListToolbar,
  EditCategoryForm,
} from '../sections/@dashboard/category';
import CategoryService from '../services/category-service';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import KEYS from '../helpers/keys';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'isroot', label: 'A la racine', alignRight: false },
  { id: 'parent', label: 'Parent', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function CategoryPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [categoryList, setCategoryList] = useState([]);

  const [totalSize, setTotalSize] = useState(0);

  const [loading, setLoading] = useState(false);

  const [circleProgress, setCircleProgress] = useState(true);

  const [filterList, setFilterList] = useState({ createdAt: -1 });

  const [showForm, setShowForm] = useState(false);

  const [showFormEdit, setShowFormEdit] = useState(false);

  const [singleSelected, setSingleSelected] = useState();

  const [showFormAttach, setShowFormAttach] = useState(false);

  const [parentCategory, setParentCategory] = useState();

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const onSelectCategory = (row) => {
    setSingleSelected(row);
    console.log(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const getAllCategories = async () => {
    setLoading(true);
    await CategoryService.allCategories()
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const categories = result.data;
            setCategoryList(categories);
          }
          setCircleProgress(false);
        },
        (error) => {
          console.log(error);
          setCircleProgress(false);
        }
      )
      .finally(() => {
        setLoading(false);
        setCircleProgress(false);
      });
  };

  const addCategory = async (categoryParam) => {
    setLoading(true);
    await CategoryService.addCategory(categoryParam)
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const category = result.data;
            console.log(category);
            setCategoryList([category, ...categoryList]);
          }
          setCircleProgress(false);
        },
        (error) => {
          console.log(error);
          setCircleProgress(false);
        }
      )
      .finally(() => {
        setLoading(false);
        setCircleProgress(false);
        setShowForm(false);
      });
  };

  const updateCategory = async (categoryParam) => {
    setLoading(true);
    await CategoryService.updateCategory(categoryParam)
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const category = result.data;
            category.parent = singleSelected?.parent;
            const newCategoryList = categoryList.map((e) => {
              if (e.id === category.id) return category;
              return e;
            });
            setCategoryList(newCategoryList);
          }
          setCircleProgress(false);
        },
        (error) => {
          console.log(error);
          setCircleProgress(false);
        }
      )
      .finally(() => {
        setLoading(false);
        setCircleProgress(false);
        setShowFormEdit(false);
        setSingleSelected(undefined);
      });
  };

  const changeRootProperty = async (isRoot) => {
    setOpen(false);
    const newCategory = { categoryId: singleSelected.id, isRoot, name: singleSelected.name };
    updateCategory(newCategory);
  };

  const deleteCategory = async () => {
    console.log(singleSelected);
    setOpen(null);
    setLoading(true);

    await CategoryService.deleteCategory(singleSelected.id)
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const category = result.data;
            category.parent = singleSelected.parent;
            const newCategoryList = categoryList.map((e) => {
              if (e.id === singleSelected.id) return category;
              return e;
            });
            setCategoryList(newCategoryList);
          }
          setCircleProgress(false);
        },
        (error) => {
          console.log(error);
          setCircleProgress(false);
        }
      )
      .finally(() => {
        setLoading(false);
        setCircleProgress(false);
      });
  };

  const activeCategory = async () => {
    setOpen(null);
    setLoading(true);

    await CategoryService.activeCategory(singleSelected.id)
      .then(
        (result) => {
          if (result.code === 'SUCCESS') {
            const category = result.data;
            category.parent = singleSelected.parent;
            const newCategoryList = categoryList.map((e) => {
              if (e.id === singleSelected.id) return category;
              return e;
            });
            setCategoryList(newCategoryList);
          }
          setCircleProgress(false);
        },
        (error) => {
          console.log(error);
          setCircleProgress(false);
        }
      )
      .finally(() => {
        setLoading(false);
        setCircleProgress(false);
      });
  };
  const dettachCategory = async () => {
    setOpen(null);
    setLoading(true);
    console.log(singleSelected);
    try {
      const body = {
        child: singleSelected.id,
        parent: singleSelected.parent._id,
      };
      const result = await CategoryService.dettachCategory(body);
      if (result.code === 'SUCCESS') {
        console.log(result);
        const newCategoryList = categoryList.map((e) => {
          if (e.id === singleSelected.id) {
            e.parent = null;
          }
          return e;
        });
        setCategoryList(newCategoryList);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const attachCategory = async () => {
    setOpen(null);
    setLoading(true);
    setShowFormAttach(false);

    try {
      const body = {
        child: singleSelected.id,
        parent: parentCategory.id,
      };
      const result = await CategoryService.attachCategory(body);
      if (result.code === 'SUCCESS') {
        console.log(result);
        const newCategoryList = categoryList.map((e) => {
          if (e.id === singleSelected.id) {
            e.parent = parentCategory;
          }
          return e;
        });
        setCategoryList(newCategoryList);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const saveCategory = (data) => {
    addCategory(data);
  };

  const setUpdateCategory = (data) => {
    updateCategory(data);
  };

  const removeCategory = (id) => {
    deleteCategory(id);
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const handleClose = () => {
    setShowForm(false);
    setShowFormEdit(false);
    setShowFormAttach(false);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categoryList.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  // const filteredUsers = userList;

  const isNotFound = !filteredUsers.length && !!filterName;
  return (
    <>
      <Helmet>
        <title> Category | List </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Categories
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            style={{ textTransform: 'none' }}
            onClick={() => setShowForm(true)}
          >
            Ajouter une categorie
          </Button>
        </Stack>
        {loading && circleProgress === false && <LinearProgress />}

        {circleProgress ? (
          <CircularProgress />
        ) : (
          <Card>
            <CategoryListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <CategoryListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={totalSize}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {categoryList.map((row) => {
                      const { id, categoryId, name, isDeleted, images, createdAt, isRoot, parent } = row;

                      const selectedCity = selected.indexOf(name) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedCity}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedCity} onChange={(event) => handleClick(event, name)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {images.length > 0 && (
                                <Avatar alt={name} src={`${KEYS.BACKEND_HOST_RESSOURCES}/categories/${images[0]}`} />
                              )}
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            <Label color={(isDeleted && 'error') || 'success'}>
                              {!isDeleted ? 'Activée' : 'Désactivée'}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            <Label color={(isRoot && 'success') || 'error'}>{isRoot ? 'Oui' : 'Non'}</Label>
                          </TableCell>

                          <TableCell align="left">
                            <Label color={(parent && 'success') || 'error'}>
                              {parent ? parent.name : 'Aucun parent'}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => {
                                handleOpenMenu(event);
                                onSelectCategory(row);
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            minWidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpen(false);
            setShowFormEdit(true);
          }}
        >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {singleSelected && singleSelected.parent ? (
          <MenuItem
            onClick={() => {
              setOpen(false);
              dettachCategory();
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Détacher de {singleSelected.parent.name}
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              setOpen(false);
              setShowFormAttach(true);
            }}
          >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Attacher à une catégorie
          </MenuItem>
        )}
        {singleSelected && singleSelected.isRoot && (
          <MenuItem sx={{}} onClick={() => changeRootProperty(false)}>
            <>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Retirer de la racine
            </>
          </MenuItem>
        )}

        {singleSelected && singleSelected.isDeleted === false && (
          <MenuItem sx={{ color: 'error.main' }} onClick={() => deleteCategory()}>
            <>
              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
              Delete
            </>
          </MenuItem>
        )}
        {singleSelected && singleSelected.isDeleted && (
          <MenuItem sx={{ color: 'success.main' }} onClick={() => activeCategory()}>
            <>
              <Iconify icon={'ei:redo'} sx={{ mr: 2 }} />
              Activer
            </>
          </MenuItem>
        )}

        {singleSelected && singleSelected.isRoot === false && (
          <MenuItem sx={{}} onClick={() => changeRootProperty(true)}>
            <>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Mettre à la racine
            </>
          </MenuItem>
        )}
        {singleSelected && singleSelected.isRoot && (
          <MenuItem sx={{}} onClick={() => changeRootProperty(false)}>
            <>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
              Retirer de la racine
            </>
          </MenuItem>
        )}
      </Popover>
      <Dialog
        open={showForm}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Ajouter une catégorie'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            <AddCategoryForm onSaveData={saveCategory} onCloseForm={handleClose} categories={categoryList} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showFormEdit}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Editer une catégorie'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            {singleSelected ? (
              <EditCategoryForm
                category={singleSelected}
                onSaveData={setUpdateCategory}
                onCloseForm={handleClose}
                categories={categoryList}
              />
            ) : (
              <></>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showFormAttach}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Attacher à une catégorie'}</DialogTitle>
        <DialogContent
          sx={{
            width: '600px',
          }}
        >
          <DialogContentText id="alert-dialog-slide-description">
            {singleSelected ? (
              <Box sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Categorie</InputLabel>
                <Select
                  sx={{ width: '100%' }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // value={age}
                  label="Ville"
                  onChange={(event) => setParentCategory(event.target.value)}
                >
                  {categoryList.map((category, index) => (
                    <MenuItem value={category} key={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                <Stack direction="row" spacing={1} sx={{ mt: 1.5 }} style={{ justifyContent: 'right' }}>
                  <Button onClick={handleClose}>Annuler</Button>
                  <Button
                    onClick={() => {
                      attachCategory();
                      console.log('attach');
                    }}
                  >
                    Enregistrer
                  </Button>
                </Stack>
              </Box>
            ) : (
              <></>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CategoryPage;
