import axios from 'axios';
import CookieService from '../cookie-service';
import KEYS from '../helpers/keys';

const authHeader = () => {
  const token = CookieService.getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  return {};
};

const allCategories = async () => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/all-categories-admin`;

  const headers = authHeader();

  const response = await axios.get(url, { headers });

  return response.data;
};
const addCategory = async (body) => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/add-category`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};
const updateCategory = async (body) => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/update-category`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const deleteCategory = async (id) => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/delete-category/${id}`;

  const headers = authHeader();

  const response = await axios.delete(url, { headers });
  return response.data;
};
const activeCategory = async (id) => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/active-category/${id}`;

  const headers = authHeader();

  const response = await axios.put(url, { headers });
  return response.data;
};

const dettachCategory = async (body) => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/dettach-category`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const attachCategory = async (body) => {
  const url = `${KEYS.BACKEND_HOST_STRUCTURE}/attach-category`;

  const headers = authHeader();

  const response = await axios.post(url, body, { headers });
  return response.data;
};

const CategoryService = {
  addCategory,
  allCategories,
  deleteCategory,
  activeCategory,
  updateCategory,
  dettachCategory,
  attachCategory,
};
export default CategoryService;
